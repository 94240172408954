import { ActionReducerMapBuilder, createSlice } from "@reduxjs/toolkit";
import {
  createBalanceIntegration,
  createRsIntegration,
  deleteRsIntegration,
  getIntegrationList,
  createApricotIntegration,
} from "./integrationAction";
import { LoaderType } from "../type";
import { IntegrationData } from "./integration.types";

export type IntegrationState = {
  hasError: boolean;
  loading: {
    list: LoaderType;
    rs: LoaderType;
    balance: LoaderType;
    delete: LoaderType;
    apricot: LoaderType;
  };
  integrationData: null | IntegrationData;
};

const initialState: IntegrationState = {
  hasError: false,
  loading: {
    list: "idle",
    rs: "idle",
    balance: "idle",
    delete: "idle",
    apricot: "idle",
  },
  integrationData: null,
};

export const integrationSlice = createSlice({
  name: "integrationSlice",
  initialState,
  reducers: {},
  extraReducers: (builder: ActionReducerMapBuilder<IntegrationState>) => {
    builder
      // integration
      .addCase(getIntegrationList.pending, (state) => {
        state.loading.list = "pending";
      })
      .addCase(getIntegrationList.fulfilled, (state, { payload }) => {
        state.loading.list = "succeeded";
        state.integrationData = payload;
      })
      .addCase(getIntegrationList.rejected, (state) => {
        state.loading.list = "failed";
        state.hasError = true;
      })
      // delete integration
      .addCase(deleteRsIntegration.pending, (state) => {
        state.loading.delete = "pending";
      })
      .addCase(deleteRsIntegration.fulfilled, (state) => {
        state.loading.delete = "succeeded";
      })
      .addCase(deleteRsIntegration.rejected, (state) => {
        state.loading.delete = "failed";
        state.hasError = true;
      })
      // createRsIntegration
      .addCase(createRsIntegration.pending, (state) => {
        state.loading.rs = "pending";
      })
      .addCase(createRsIntegration.fulfilled, (state) => {
        state.loading.rs = "succeeded";
      })
      .addCase(createRsIntegration.rejected, (state) => {
        state.loading.rs = "failed";
        state.hasError = true;
      })
      // createBalanceIntegration
      .addCase(createBalanceIntegration.pending, (state) => {
        state.loading.balance = "pending";
      })
      .addCase(createBalanceIntegration.fulfilled, (state) => {
        state.loading.balance = "succeeded";
      })
      .addCase(createBalanceIntegration.rejected, (state) => {
        state.loading.balance = "failed";
        state.hasError = true;
      })
      //createApricotIntegration
      .addCase(createApricotIntegration.pending, (state) => {
        state.loading.apricot = "pending";
      })
      .addCase(createApricotIntegration.fulfilled, (state) => {
        state.loading.apricot = "succeeded";
      })
      .addCase(createApricotIntegration.rejected, (state) => {
        state.loading.apricot = "failed";
        state.hasError = true;
      });
  },
});

export default integrationSlice.reducer;
