import React, { MouseEvent } from "react";
import { Wrapper } from "./ButtonIcon.style";
import Icon, { IconNameOptions } from "../icon/Icon";
import {
  ButtonColorsType,
  ButtonSizesType,
  ButtonType,
  ButtonVariantsType,
} from "../../../types/buttonType";

interface IconButton {
  iconName: IconNameOptions;
  size: ButtonSizesType;
  variant: ButtonVariantsType;
  color?: ButtonColorsType;
  type?: ButtonType;
  loading?: boolean;
  disabled?: boolean;
  onClick?: (e: MouseEvent<HTMLButtonElement>) => void;
}

const ButtonIcon = ({
  iconName,
  size,
  type,
  variant,
  color = "success",
  disabled,
  loading,
  onClick,
}: IconButton) => {
  return (
    <Wrapper
      size={size}
      variant={variant}
      color={color}
      disabled={Boolean(disabled || loading)}
      type={type}
      onClick={onClick}
    >
      <Icon iconName={iconName} height={20} width={20} fill={"white"} />
    </Wrapper>
  );
};

export default ButtonIcon;
