import { css } from "@emotion/react";
import styled from "@emotion/styled";

export const Wrapper = styled.div<{ isChecked: boolean }>(
  ({ theme, isChecked }) => css`
    width: 18px;
    height: 18px;
    border-radius: 5px;
    border: ${isChecked ? "none" : "2px solid #E0E0E0"};
    background-color: ${isChecked ? "#5154C0" : "#FFFFFF"};
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    transition: all 0.2s ease;
  `
);
