import React from "react";
import { isMobile } from "react-device-detect";
import Icon from "../../../shared/icon/Icon";
import Button from "../../../v2/button/Button";
import Modal from "../../../v2/modal/Modal";
import {
  ContentWrapper,
  IconWrapper,
  SubTitle,
  Title,
  Wrapper,
} from "./SuccessModal.style";
import { useTranslation } from "react-i18next";

interface SuccessModalProps {
  title: string;
  subTitle: string;
  handleClose: () => void;
}
const SuccessModal = ({ title, subTitle, handleClose }: SuccessModalProps) => {
  const { t } = useTranslation();
  return (
    <Modal
      size={"small"}
      modalTitle={"Status"}
      footer={
        <Button
          onClick={handleClose}
          label={t("SuccessModal.Button")}
          size={"small"}
          variant="text"
        />
      }
      onClose={handleClose}
    >
      <Wrapper isMobile={isMobile}>
        <IconWrapper>
          <Icon iconName="checkMark" fill="white" width={32} height={32} />
        </IconWrapper>
        <ContentWrapper>
          <Title>{title}</Title>
          <SubTitle>{subTitle}</SubTitle>
        </ContentWrapper>
      </Wrapper>
    </Modal>
  );
};

export default SuccessModal;
