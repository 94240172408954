import { css } from "@emotion/react";
import styled from "@emotion/styled";

export const Container = styled.div`
  margin: 32px 0;
  display: flex;
  flex-direction: column;
  gap: 24px;
`;

export const PageTitle = styled.div`
  height: 27px;
  width: 120px;
  background-color: ${({ theme }) => theme.colors.greyShade100};
  border-radius: 5px;
`;

export const IntegrationWrapper = styled.div(
  ({ theme }) => css`
    display: flex;
    flex-direction: column;
    padding: 24px;
    gap: 16px;
    border-radius: 12px;
    background: ${theme.colors.white};
    box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.09);
  `
);

export const IntegrationTitle = styled.div`
  height: 20px;
  width: 140px;
  background-color: ${({ theme }) => theme.colors.greyShade100};
  border-radius: 5px;
`;

export const Item = styled.div(
  ({ theme }) => css`
    display: flex;
    gap: 16px;
    width: 100%;
    padding: 24px;
    border-radius: 12px;
    border: 1px solid ${theme.colors.greyShade100};
  `
);

export const Image = styled.div`
  height: 64px;
  min-width: 64px;
  border-radius: 32px;
  background-color: ${({ theme }) => theme.colors.greyShade100};
`;

export const Information = styled.div`
  display: flex;
  width: 100%;
`;

export const InformationText = styled.div`
  display: flex;
  flex-direction: column;
  gap: 7px;
  flex: 1;
`;

export const Title = styled.div`
  height: 20px;
  width: 100px;
  background-color: ${({ theme }) => theme.colors.greyShade100};
  border-radius: 5px;
`;

export const Description = styled.div`
  height: 40px;
  max-width: 450px;
  background-color: ${({ theme }) => theme.colors.greyShade100};
  border-radius: 5px;
`;

export const InformationAction = styled.div`
  display: flex;
  gap: 8px;
  align-items: center;
`;

export const ButtonCancelation = styled.div`
  height: 36px;
  width: 115px;
  background-color: ${({ theme }) => theme.colors.greyShade100};
  border-radius: 5px;
`;

export const ButtonEdit = styled.div`
  height: 36px;
  width: 36px;
  background-color: ${({ theme }) => theme.colors.greyShade100};
  border-radius: 5px;
`;
