import { combineReducers, configureStore } from "@reduxjs/toolkit";
import { TypedUseSelectorHook, useDispatch, useSelector } from "react-redux";
import userReducer from "./user/userSlice";
import reportReducer from "./report/reportSlice";
import companyReducer from "./company/companySlice";
import dashboardReducer from "./dashboard/dashboardSlice";
import mappingReducer from "./mapping/mappingSlice";
import integrationReducer from "./integration/integrationSlice";

const rootReducer = combineReducers({
  user: userReducer,
  report: reportReducer,
  company: companyReducer,
  dashboard: dashboardReducer,
  mapping: mappingReducer,
  integration: integrationReducer,
});

export const store = configureStore({
  reducer: rootReducer,
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;

export const useAppDispatch: () => AppDispatch = useDispatch;
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector;
