import { css } from "@emotion/react";
import styled from "@emotion/styled";

export const Wrapper = styled.div(
  ({ theme }) => css`
    position: relative;
    cursor: pointer;
    width: 36px;
    height: 36px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 8px;
    background-color: ${theme.colors.primary100};
  `
);

export const NotificationNumber = styled.div(
  ({ theme }) => css`
    position: absolute;
    top: -10px;
    right: -10px;
    border-radius: 100px;
    background: ${theme.colors.negative600};
    color: ${theme.colors.white};
    padding: 2px 5px;
    font-size: 10px;
    line-height: normal;
  `
);
