import { css } from "@emotion/react";
import styled from "@emotion/styled";

export const Wrapper = styled.div<{ isMobile: boolean }>(
  ({ theme, isMobile }) => css`
    ${theme.fonts.HelveticaNeue}
    display: flex;
    gap: ${isMobile ? "12px" : "16px"};
    padding: ${isMobile ? "16px" : "24px"};
    border-radius: 12px;
    border: 1px solid ${theme.colors.greyShade100};
    flex-wrap: wrap;
  `
);
export const Container = styled.div<{ isMobile: boolean }>(
  ({ isMobile }) => css`
    display: flex;
    flex: 1;
    justify-content: space-between;
    align-items: center;
    gap: ${isMobile ? "12px" : " 48px"};
    flex-wrap: wrap;
  `
);

export const MainContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 6px;
`;
export const Title = styled.h6<{ isMobile: boolean }>(
  ({ theme, isMobile }) => css`
    color: ${theme.colors.textPrimary};
    font-size: ${isMobile ? "13px" : "16px"};
    line-height: normal;
  `
);
export const Description = styled.p<{ isMobile: boolean }>(
  ({ theme, isMobile }) => css`
    ${theme.fonts.HelveticaLight}
    color: ${theme.colors.textSecondary};
    font-size: ${isMobile ? "11px" : "13px"};
    line-height: 20px;
  `
);

export const ActionWRapper = styled.div<{ isConnected?: boolean }>(
  ({ isConnected }) => css`
    display: flex;
    align-items: center;
    gap: ${isConnected ? "8px" : "4px"};
    ${!isConnected &&
    css`
      cursor: pointer;
    `}
  `
);
export const ConnectText = styled.p<{ isMobile: boolean }>(
  ({ theme, isMobile }) => css`
    color: ${theme.colors.primary600};
    font-size: ${isMobile ? "12px" : "14px"};
    font-weight: 500;
    line-height: 20px;
    letter-spacing: -0.084px;
  `
);
export const IconWrapper = styled.div`
  width: 20px;
  height: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
`;
export const TitleWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
`;
export const ConnectedStatusContentWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 4px;
`;

export const ValidText = styled.p(
  ({ theme }) => css`
    margin: 0;
    color: ${theme.colors.success600};
    font-size: 10px;
    line-height: normal;
  `
);

export const ValidIconWrapper = styled.div(
  ({ theme }) => css`
    display: flex;
    align-items: center;
    justify-content: center;
    width: 12px;
    height: 12px;
    border-radius: 50%;
    background: ${theme.colors.success600};
  `
);

export const InValidIconWrapper = styled.div(
  ({ theme }) => css`
    display: flex;
    align-items: center;
    justify-content: center;
    width: 12px;
    height: 12px;
    border-radius: 50%;
    background: ${theme.colors.warning500};
  `
);

export const InValidText = styled.p(
  ({ theme }) => css`
    margin: 0;
    color: ${theme.colors.warning500};
    font-size: 10px;
    line-height: normal;
  `
);
