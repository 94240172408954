import { css } from "@emotion/react";
import styled from "@emotion/styled";

export const Wrapper = styled.div<{ isMobile: boolean }>(
  ({ isMobile }) => css`
    display: flex;
    flex-direction: column;
    gap: ${isMobile ? "16px" : "24px"};
    padding: ${isMobile ? "24px" : "32px 64px"};
  `
);

export const TitleWrapper = styled.div`
  display: flex;
  flex-direction: column;
  text-align: center;
  gap: 6px;
`;
export const Title = styled.h6(
  ({ theme }) => css`
    color: ${theme.colors.textPrimary};
    font-size: 14px;
    line-height: 18px;
  `
);

export const SubTitle = styled.p(
  ({ theme }) => css`
    ${theme.fonts.HelveticaLight}
    color: ${theme.colors.textSecondary};
    margin: 0;
    font-size: 12px;
    line-height: 18px;
  `
);

export const InputWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 12px;
`;
export const ButtonWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
`;
export const ErrorMassage = styled.div(
  ({ theme }) => css`
    color: ${theme.colors.negative600};
    background-color: ${theme.colors.negative100};
    padding: 12px 16px;
    font-size: 12px;
    line-height: normal;
    border-radius: 8px;
    display: flex;
    align-items: center;
    gap: 8px;
  `
);

export const IconWrapper = styled.div(
  ({ theme }) => css`
    display: flex;
    align-items: center;
    justify-content: center;
    width: 20px;
    height: 20px;
    border-radius: 50%;
    background-color: ${theme.colors.negative600};
  `
);
