import React from "react";

import {
  ButtonWrapper,
  ContentWrapper,
  IconWrapper,
  NameTitle,
  SubTitle,
  Title,
  Wrapper,
} from "./DeleteConfirmationModal.style";

import { useTranslation } from "react-i18next";
import Icon from "../../../shared/icon/Icon";
import Button from "../../../v2/button/Button";
import Modal from "../../../v2/modal/Modal";

interface DeleteConfirmationModalProps {
  name: string;

  handleClose: () => void;
  handleClick: () => void;
}
const DeleteConfirmationModal = ({
  name,

  handleClose,
  handleClick,
}: DeleteConfirmationModalProps) => {
  const { t } = useTranslation();
  return (
    <Modal
      footer={
        <ButtonWrapper>
          <Button
            label={t("DeleteModal.CancelButton")}
            size="medium"
            variant="text"
            onClick={handleClose}
          />
          <Button
            label={t("DeleteModal.DeleteButton")}
            size="medium"
            variant="filled"
            onClick={handleClick}
          />
        </ButtonWrapper>
      }
      size="small"
      modalTitle={t("DeleteModal.ModalTitle")}
      onClose={handleClose}
    >
      <Wrapper>
        <IconWrapper>
          <Icon fill="white" iconName="closeIcon" height={30} width={30} />
        </IconWrapper>
        <ContentWrapper>
          <Title>
            {t("DeleteModal.ModalBodyTitle")}: <NameTitle> {name}</NameTitle>
          </Title>
          <SubTitle>{t("DeleteModal.ModalBodySubTitle")}</SubTitle>
        </ContentWrapper>
      </Wrapper>
    </Modal>
  );
};

export default DeleteConfirmationModal;
