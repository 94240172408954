import React from "react";
import CustomIcons from "./IconSvgs";

const colorMap: Record<string, { color: any }> = {
  primary: { color: "#5154C0" },
  primary100: { color: "#4946AF" },
  default: { color: "#383838" },
  lightBlack: { color: "#454545" },
  black100: { color: "#242424" },
  danger: { color: "#EC1818" },
  danger100: { color: "#DF1C41" },
  danger200: { color: "#BF1137" },
  white: { color: "#fff" },
  grey100: { color: "#BDBDBD" },
  grey200: { color: "#989898" },
  grey300: { color: "#888888" },
};

export type IconNameOptions = keyof typeof CustomIcons;

export interface CustomIconProps {
  iconName: IconNameOptions;
  width?: number;
  height?: number;
  fill?:
    | "primary"
    | "primary100"
    | "default"
    | "danger"
    | "danger100"
    | "danger200"
    | "white"
    | "grey100"
    | "grey200"
    | "grey300"
    | "lightBlack"
    | "black100";
}

const Icon = ({
  iconName,
  width = 24,
  height = 24,
  fill = "default",
}: CustomIconProps) => {
  const IconComponent = CustomIcons[iconName];
  const { color } = colorMap[fill];
  if (!IconComponent) {
    return null;
  }

  return <IconComponent fill={color} width={width} height={height} />;
};

export default Icon;
