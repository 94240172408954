import React from "react";

import {
  Container,
  PageTitle,
  IntegrationWrapper,
  IntegrationTitle,
  Item,
  Image,
  Information,
  InformationText,
  Title,
  Description,
  InformationAction,
  ButtonCancelation,
  ButtonEdit,
} from "./IntegrationSkeleton.style.";

const IntegrationSkeleton = () => {
  return (
    <Container>
      <PageTitle />
      <IntegrationWrapper>
        <IntegrationTitle />
        <Item>
          <Image />
          <Information>
            <InformationText>
              <Title />
              <Description />
            </InformationText>
            <InformationAction>
              <ButtonCancelation />
              <ButtonEdit />
            </InformationAction>
          </Information>
        </Item>
        <Item>
          <Image />
          <Information>
            <InformationText>
              <Title />
              <Description />
            </InformationText>
            <InformationAction>
              <ButtonCancelation />
              <ButtonEdit />
            </InformationAction>
          </Information>
        </Item>
      </IntegrationWrapper>
    </Container>
  );
};

export default IntegrationSkeleton;
