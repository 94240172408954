import React, { useEffect, useState } from "react";
import Modal from "../../../v2/modal/Modal";
import {
  ButtonWrapper,
  CheckboxWrapper,
  Content,
  Item,
  ListWrapper,
  SubTitle,
  Title,
  TitleWrapper,
  Wrapper,
} from "./NotificationModal.style";
import Button from "../../../v2/button/Button";
import CustomCheckbox from "../../../v2/checkbox/Checkbox";
import { isMobile } from "react-device-detect";
import { NotificationType, User } from "../../../../store/user/user.types";
import { useTranslation } from "react-i18next";

interface NotificationModalProps {
  notifications: Array<NotificationType>;
  handleClose: () => void;
  onClick: (notifications: Array<number>) => void;
}

const notificationData = [
  { title: "NotificationModal.NotificationDataTitleUpdated", id: 1 },
  { title: "NotificationModal.NotificationDataTitleNotified", id: 2 },
];

const isEqual = (array1: Array<number>, array2: Array<number>) => {
  if (array1.length !== array2.length) return false;

  return array1.sort().every((value, index) => value === array2.sort()[index]);
};

const NotificationModal = ({
  notifications,
  handleClose,
  onClick,
}: NotificationModalProps) => {
  const { t } = useTranslation();
  const [equalArrays, setEqualArrays] = useState(true);
  const [selectedItems, setSelectedItems] = useState<number[]>(
    notifications.map((notification) => notification.clientNotificationType) ||
      []
  );

  const notificationType = notifications.map(
    (item) => item.clientNotificationType
  );

  useEffect(() => {
    setEqualArrays(isEqual(selectedItems, notificationType));
  }, [selectedItems]);

  const toggleItem = (item: number) => {
    setSelectedItems((prev) =>
      prev.includes(item)
        ? prev.filter((element) => element !== item)
        : [...prev, item]
    );
  };

  const handleSave = () => {
    onClick(selectedItems);
  };

  return (
    <Modal
      size="small"
      modalTitle={t("NotificationModal.ModalTitle")}
      footer={
        <ButtonWrapper>
          <Button
            onClick={handleClose}
            label={t("NotificationModal.CloseButton")}
            size="small"
            variant="text"
          />
          <Button
            onClick={handleSave}
            label={t("NotificationModal.SaveButton")}
            size="small"
            variant="filled"
            disabled={equalArrays}
          />
        </ButtonWrapper>
      }
      onClose={handleClose}
    >
      <Wrapper isMobile={isMobile}>
        <TitleWrapper>
          <Title>{t("NotificationModal.Title")}</Title>
          <SubTitle>{t("NotificationModal.SubTitle")}</SubTitle>
        </TitleWrapper>
        <ListWrapper>
          {notificationData.map((item, index) => (
            <Item key={item.id} onClick={() => toggleItem(item.id)}>
              <CheckboxWrapper>
                <CustomCheckbox
                  checked={selectedItems.includes(item.id)}
                  onClick={() => {}}
                />
              </CheckboxWrapper>
              <Content lastItem={index === notificationData.length - 1}>
                {t(item.title)}
              </Content>
            </Item>
          ))}
        </ListWrapper>
      </Wrapper>
    </Modal>
  );
};

export default NotificationModal;
