import { css } from "@emotion/react";
import styled from "@emotion/styled";

export const Wrapper = styled.div<{ hasWarning: boolean }>(
  ({ theme, hasWarning }) => css`
    position: relative;
    display: flex;
    align-items: center;
    border-radius: 100px;
    gap: 4px;
    font-size: 12px;
    line-height: normal;
    padding: ${hasWarning ? "4px 8px 4px 4px" : "4px 8px"};
    ${theme.fonts.HelveticaNeue}
    color: ${theme.colors.textPrimary};
    background: ${hasWarning
      ? theme.colors.warningShade300
      : theme.colors.grey100};
  `
);

export const HoverItem = styled.div(
  ({ theme }) => css`
    position: absolute;
    bottom: 31px;
    right: 50%;
    transform: translate(50%, 0);
    z-index: 10;
    padding: 6px 12px;
    border-radius: 8px;
    width: max-content;
    background: ${theme.colors.black005};
    color: ${theme.colors.white};
    font-size: 12px;
    line-height: 20px;

    &::before {
      content: "";
      position: absolute;
      right: 50%;
      top: 90%;
      transform: translateY(-50%);
      border-width: 6px;
      border-style: solid;
      border-color: ${theme.colors.black005};
      rotate: 45deg;
      border-radius: 3px;
    }
  `
);
