import { Field, Form, Formik, FormikProps } from "formik";
import React, { useRef } from "react";
import Modal from "../../../v2/modal/Modal";
import {
  ButtonWrapper,
  ErrorMassage,
  IconWrapper,
  InputWrapper,
  SubTitle,
  Title,
  TitleWrapper,
  Wrapper,
} from "./ApricotIntegrationModal.style";
import Input from "../../../v2/input/Input";
import Button from "../../../v2/button/Button";
import * as Yup from "yup";
import { useTranslation } from "react-i18next";
import { isMobile } from "react-device-detect";
import Icon from "../../../shared/icon/Icon";

interface FormValues {
  username: string;
  password: string;
  endPointUrl: string;
  dbName: string;
}

interface ApricotIntegrationModalProps {
  title: string;
  initialValue: {
    username: string;
    password: string;
    endPointUrl: string;
    dbName: string;
  };
  loading: boolean;
  errorMassage: string;
  handleSubmit: (data: FormValues) => void;
  handleClose: () => void;
}

const ApricotIntegrationModal = ({
  title,
  initialValue,
  loading,
  errorMassage,
  handleSubmit,
  handleClose,
}: ApricotIntegrationModalProps) => {
  const { t } = useTranslation();
  const formikRef = useRef<FormikProps<FormValues> | null>(null);

  const validationSchema = Yup.object().shape({
    username: Yup.string().required("Username is required"),
    password: Yup.string().required("Password is required"),
    endPointUrl: Yup.string().required("URL is required"),
    dbName: Yup.string().required("dbName is required"),
  });

  return (
    <Modal
      size="small"
      modalTitle={t("ApricotIntegrationModal.ModalTitle")}
      footer={
        <ButtonWrapper>
          <Button
            onClick={handleClose}
            label={t("ApricotIntegrationModal.CloseButton")}
            size="small"
            variant="text"
          />
          <Button
            loading={loading}
            onClick={() => {
              formikRef.current?.submitForm();
            }}
            label={t("ApricotIntegrationModal.ApplyButton")}
            size="small"
            variant="filled"
          />
        </ButtonWrapper>
      }
      onClose={handleClose}
    >
      <Wrapper isMobile={isMobile}>
        <TitleWrapper>
          <Title>{title}</Title>
          <SubTitle>{"ApricotIntegrationModal.Description"}</SubTitle>
        </TitleWrapper>
        <Formik
          innerRef={formikRef}
          initialValues={initialValue}
          validationSchema={validationSchema}
          onSubmit={(values) => {
            handleSubmit(values);
          }}
        >
          {() => (
            <Form>
              <InputWrapper>
                <Field
                  id={"apricotServiceCredentialUsername"}
                  name="username"
                  label={t("ApricotIntegrationModal.UserName")}
                  component={Input}
                />
                <Field
                  id={"apricotServiceCredentialPassword"}
                  name="password"
                  type="password"
                  label={t("ApricotIntegrationModal.Password")}
                  component={Input}
                />
                <Field
                  id={"apricotServiceCredentialEndPoint"}
                  name="endPointUrl"
                  label={t("ApricotIntegrationModal.Url")}
                  component={Input}
                />
                <Field
                  id={"apricotServiceCredentialDbName"}
                  name="dbName"
                  label={t("ApricotIntegrationModal.DbName")}
                  component={Input}
                />
                {errorMassage && (
                  <ErrorMassage>
                    <IconWrapper>
                      <Icon
                        fill="white"
                        iconName="close"
                        width={9}
                        height={9}
                      />
                    </IconWrapper>
                    {errorMassage}
                  </ErrorMassage>
                )}
              </InputWrapper>
            </Form>
          )}
        </Formik>
      </Wrapper>
    </Modal>
  );
};

export default ApricotIntegrationModal;
