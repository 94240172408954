import { css } from "@emotion/react";
import styled from "@emotion/styled";

export const Wrapper = styled.div(
  ({ theme }) => css`
    margin: 40px 0;
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 30px;
    @media (max-width: ${theme.breakpoints.lg}) {
      grid-template-columns: 1fr;
    }
  `
);
export const DataWrapper = styled.div(
  ({ theme }) => css`
    background-color: ${theme.colors.lightGrey};
    border-radius: 15px;
    padding: 0 30px;
  `
);
export const TableContentRow = styled.div(
  ({ theme }) => css`
    padding: 10px 0;
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-bottom: 1px solid ${theme.colors.greyBorderDark};
    min-height: 80px;

    &:last-child {
      border-bottom: none;
    }
  `
);
export const TableHeaderItem = styled.div(
  ({ theme }) => css`
    ${theme.fonts.AvenirDemi}
    font-size: 12px;
    color: ${theme.colors.grey};
    margin: 0;
  `
);

export const TableUserContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 5px;
`;

export const UserWrapper = styled.div(
  ({ theme }) => css`
    display: flex;
    gap: 3px;
    align-items: center;
    font-size: 14px;
    ${theme.fonts.AvenirMedium}
    color: ${theme.colors.black};
  `
);

export const UserEmail = styled.div(
  ({ theme }) => css`
    font-size: 12px;
    ${theme.fonts.AvenirDemi};
    color: ${theme.colors.grey};
    margin: 0;
  `
);

export const DropDownItem = styled.div`
  width: 140px;
`;

export const TableContent = styled.div(
  ({ theme }) => css`
    font-size: 14px;
    ${theme.fonts.AvenirMedium};
    margin: 0;
  `
);
