import React from "react";
import { useTranslation } from "react-i18next";

import { User } from "../../../store/user/user.types";
import {
  DataWrapper,
  TableContentRow,
  TableUserContainer,
  UserWrapper,
  TableHeaderItem,
  Wrapper,
  UserEmail,
  DropDownItem,
  TableContent,
} from "./UserManagementMobile.style";
import DropDown from "../../shared/dropdown/Dropdown";

import { formatDate } from "../../../helpers/helper";
import TableItemLoader from "../../shared/tableItemLoader/TableItemLoader";
import Notification from "../../../components/v2/notification/Notification";

interface UserManagementMobileProps {
  users: Array<User>;
  userRoles: Array<{ value: string; label: string }>;
  userStatues: Array<{ value: string; label: string }>;
  loadingIds: Array<number>;
  headers: Array<string>;
  updateUserRole: (user: User, roleId: string) => void;
  updateUserStatus: (user: User, statusId: string) => void;
  onClick: (user: User) => void;
}

const UserManagementMobile = ({
  users,
  userRoles,
  userStatues,
  loadingIds,
  headers,
  updateUserRole,
  updateUserStatus,
  onClick,
}: UserManagementMobileProps) => {
  const { t } = useTranslation();

  return (
    <Wrapper>
      {users.map((item) => (
        <DataWrapper key={item.id}>
          <TableContentRow>
            <TableHeaderItem>{t(headers[0])}</TableHeaderItem>
            <TableUserContainer>
              <UserWrapper>
                {item.firstName}
                {item.lastName}
              </UserWrapper>
              <UserEmail>{item.email}</UserEmail>
            </TableUserContainer>
          </TableContentRow>
          <TableContentRow>
            <TableHeaderItem>{t(headers[1])}</TableHeaderItem>
            {!loadingIds.includes(item?.clientCompany?.id || 0) ? (
              <DropDownItem>
                <DropDown
                  selectedValue={
                    item?.clientCompany?.clientRoleId?.toString() || ""
                  }
                  handleSelect={(value) => {
                    updateUserRole(item, value);
                  }}
                  title={""}
                  options={userRoles}
                />
              </DropDownItem>
            ) : (
              <TableItemLoader text={t("UserManagement.TableItemLoader")} />
            )}
          </TableContentRow>
          <TableContentRow>
            <TableHeaderItem>{t(headers[2])}</TableHeaderItem>
            {!loadingIds.includes(item?.clientCompany?.id || 0) ? (
              <DropDownItem>
                <DropDown
                  selectedValue={item?.clientCompany?.status.toString() || ""}
                  handleSelect={(value) => {
                    updateUserStatus(item, value);
                  }}
                  title={""}
                  options={userStatues}
                />
              </DropDownItem>
            ) : (
              <TableItemLoader text={t("UserManagement.TableItemLoader")} />
            )}
          </TableContentRow>
          <TableContentRow>
            <TableHeaderItem>{t(headers[3])}</TableHeaderItem>
            <TableContent>{formatDate(item.createdAt)}</TableContent>
          </TableContentRow>
          <TableContentRow>
            <TableHeaderItem>{t(headers[4])}</TableHeaderItem>
            <TableContent>
              {item.updatedAt === "0001-01-01T00:00:00"
                ? t("UserManagement.Never")
                : formatDate(item.updatedAt)}
            </TableContent>
          </TableContentRow>

          <TableContentRow>
            <TableHeaderItem>{t(headers[5])}</TableHeaderItem>
            <TableContent>
              <Notification
                onClick={() => {
                  onClick(item);
                }}
                notificationNumber={
                  item.clientCompany?.clientNotifications.length || 0
                }
              />
            </TableContent>
          </TableContentRow>
        </DataWrapper>
      ))}
    </Wrapper>
  );
};

export default UserManagementMobile;
