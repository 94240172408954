import { Pages, userPermissions } from "../store/user/user.types";

export const AuthURL = {
  Root: { path: "/" },
  Login: { path: "/login" },
  ForgotPassword: { path: "/forgot-password" },
  CheckEmail: { path: "/check-email" },
  ResetPassword: { path: "/reset-password" },
  ChooseAccount: { path: "/choose-account" },
};

export type PageType = {
  [key in Pages]: { path: string; permissionKey?: keyof userPermissions };
};

export const PageURL: PageType = {
  Root: { path: "/app" },
  Home: { path: "home" },
  ImportData: { path: "import-data", permissionKey: "ImportData" },
  Mapping: { path: "Mapping", permissionKey: "Mapping" },
  UserManagement: { path: "UserManagement", permissionKey: "Administration" },
  PersonalInfo: {
    path: "updatePersonalDetail",
    permissionKey: "PersonalInfo",
  },
  UpdatePassword: { path: "updatePassword", permissionKey: "PersonalInfo" },
  CreateCompany: { path: "createCompany", permissionKey: "CreateCompany" },
  CompanyInfo: { path: "CompanyInfo" },
  Integration: { path: "Integration" },
  FAQ: { path: "FAQ" },
  notFound: { path: "404" },
  PermissionDenied: { path: "permission-denied" },
  Revenue: { path: "Revenue", permissionKey: "Revenue" },
  Expenses: { path: "Expenses", permissionKey: "Expenses" },
  ProfitLoss: { path: "ProfitLoss", permissionKey: "ProfitLoss" },
  Reportal: { path: "Reportal", permissionKey: "Reportal" },
  CashFlow: { path: "CashFlow", permissionKey: "CashFlow" },
  RevenueServices: {
    path: "RevenueServices",
    permissionKey: "RevenueService",
  },
  Reports: { path: "Reports", permissionKey: "Reports" },
  Market: { path: "Market" },
};
