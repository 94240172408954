import {
  LinkedButtonColorsType,
  LinkedButtonSizesType,
} from "../../../types/buttonType";
import React, { useState } from "react";
import { Label, Wrapper } from "./LinkedButton.style";
import Icon from "../../shared/icon/Icon";
interface LinkedButtonProps {
  label: string;
  color: LinkedButtonColorsType;
  size?: LinkedButtonSizesType;
  onClick: () => void;
}
const LinkedButton = ({
  label,
  color,
  size = "large",
  onClick,
}: LinkedButtonProps) => {
  const [isHovered, setIsHovered] = useState(false);
  const [isActive, setIsActive] = useState(false);

  const iconColor = () => {
    switch (color) {
      case "primary":
        return isActive ? "primary" : "primary100";
      case "error":
        return isActive ? "danger100" : "danger200";
      case "black":
        return isActive ? "black100" : "black100";
      default:
        return isActive ? "grey200" : "grey300";
    }
  };

  return (
    <Wrapper
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => {
        setIsHovered(false);
        setIsActive(false);
      }}
      onMouseDown={() => setIsActive(true)}
      onMouseUp={() => setIsActive(false)}
      onClick={onClick}
    >
      <Label
        size={size}
        color={color}
        isHovered={isHovered}
        isActive={isActive}
      >
        {label}
      </Label>
      <Icon
        iconName="rightArrow"
        fill={iconColor()}
        width={size === "large" ? 20 : 16}
        height={size === "large" ? 20 : 16}
      />
    </Wrapper>
  );
};

export default LinkedButton;
