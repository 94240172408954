import React from "react";
import Icon from "../../shared/icon/Icon";
import { NotificationNumber, Wrapper } from "./Notification.style";

interface NotificationProps {
  notificationNumber: number;
  onClick: () => void;
}
const Notification = ({ notificationNumber, onClick }: NotificationProps) => {
  return (
    <Wrapper onClick={onClick}>
      {Boolean(notificationNumber) && (
        <NotificationNumber>{notificationNumber}</NotificationNumber>
      )}
      <Icon width={20} height={20} iconName="notification" />
    </Wrapper>
  );
};

export default Notification;
