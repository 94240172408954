import { css } from "@emotion/react";
import styled from "@emotion/styled";

export const Wrapper = styled.div<{ isMobile: boolean }>(
  ({ isMobile }) => css`
    padding: ${isMobile ? "20px 0" : "50px 0"};
    width: 100%;
  `
);
export const Header = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export const LoaderWrapper = styled.div`
  height: calc(100vh - 250px);
`;

export const TitleWrapper = styled.div`
  margin-bottom: 30px;
`;
export const CustomTable = styled.table`
  width: 100%;
  position: relative;
`;

export const TableRow = styled.tr(
  ({ theme }) => css`
    margin: 20px 0 30px;
    border-top: 1px solid ${theme.colors.greyBorderDark};

    td {
      padding: 20px 0;
    }
  `
);

export const TableRowItem = styled.td(
  ({ theme }) => css`
    vertical-align: middle;
    ${theme.fonts.AvenirMedium}
    color: ${theme.colors.black};
  `
);

export const Title = styled.h1(
  ({ theme }) => css`
    font-size: 18px;
    ${theme.fonts.AvenirDemi};

    @media (max-width: ${theme.breakpoints.lg}) {
      font-size: 16px;
    }
  `
);
export const Results = styled.p(
  ({ theme }) => css`
    ${theme.fonts.AvenirDemi}
    font-size: 12px;
    color: ${theme.colors.grey};
    @media (max-width: ${theme.breakpoints.lg}) {
      font-size: 10px;
    }
  `
);
