import { t } from "i18next";
import { useEffect, useRef, useState } from "react";

import Icon from "../../shared/icon/Icon";
import {
  MoreContainer,
  MoreContainerItem,
  MoreContainerItemContent,
  MoreIconWrapper,
} from "./MappingItemActions.style";
interface MappingItemActionsProps {
  hasMappingItems?: boolean;
  handleEdit: () => void;
  handleDelete: () => void;
  handleAllItemDelete?: () => void;
}
const MappingItemActions = ({
  hasMappingItems = true,
  handleEdit,
  handleDelete,
  handleAllItemDelete,
}: MappingItemActionsProps) => {
  const [isOpen, setIsOpen] = useState(false);
  const optionRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (
        optionRef?.current &&
        !optionRef.current.contains(event.target as Node)
      ) {
        setIsOpen(false);
      }
    };

    if (optionRef?.current) {
      document.addEventListener("mousedown", handleClickOutside);
    }

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [optionRef]);
  return (
    <>
      <MoreIconWrapper
        ref={optionRef}
        onClick={(e) => {
          e.stopPropagation();
          setIsOpen((prev) => !prev);
        }}
      >
        <Icon iconName="dots" height={16} width={16} fill="grey200" />
        {isOpen && (
          <MoreContainer>
            <MoreContainerItem
              onClick={(e) => {
                e.preventDefault();
                handleEdit();
              }}
            >
              <Icon height={20} width={20} iconName="pen" />
              <MoreContainerItemContent>
                {t("MappingItem.EditTitle")}
              </MoreContainerItemContent>
            </MoreContainerItem>
            {hasMappingItems && (
              <MoreContainerItem
                onClick={(e) => {
                  e.preventDefault();
                  handleDelete();
                }}
              >
                <Icon
                  height={20}
                  width={20}
                  iconName="trash_v2"
                  fill="danger"
                />
                <MoreContainerItemContent isDelete>
                  {t("MappingItem.DeleteTitle")}
                </MoreContainerItemContent>
              </MoreContainerItem>
            )}
            {handleAllItemDelete && !hasMappingItems && (
              <MoreContainerItem
                onClick={(e) => {
                  e.preventDefault();
                  handleAllItemDelete?.();
                }}
              >
                <Icon height={20} width={20} iconName="gitIcon" fill="danger" />
                <MoreContainerItemContent isDelete>
                  {t("MappingItem.DeleteAllTitle")}
                </MoreContainerItemContent>
              </MoreContainerItem>
            )}
          </MoreContainer>
        )}
      </MoreIconWrapper>
    </>
  );
};
export default MappingItemActions;
