import React, { useState } from "react";
import { User } from "../../../../store/user/user.types";
import Table from "../../../shared/table/Table";
import TableSkeleton from "../../../shared/tableSkeleton/TableSkeleton";
import UserManagementTableBody from "../userManagementTableBody/UserManagementTableBody";
import NotificationModal from "../notificationModal/NotificationModal";
import SuccessModal from "../../integration/successModal/SuccessModal";
import { useTranslation } from "react-i18next";

enum ModalType {
  IDLE = "Idle",
  NOTIFICATION = "Notification",
  SUCCESS = "Success",
}

interface UserManagementTableProps {
  users: Array<User>;
  userRoles: Array<{ value: string; label: string }>;
  userStatues: Array<{ value: string; label: string }>;
  loadingIds: Array<number>;
  loading: boolean;
  tableHeaders: Array<string>;
  updateUserRole: (user: User, roleId: string) => void;
  onClick: (user: User) => void;
  updateUserStatus: (user: User, statusId: string) => void;
}

const UserManagementTable = ({
  users,
  userStatues,
  userRoles,
  loadingIds,
  loading,
  tableHeaders,
  updateUserRole,
  onClick,
  updateUserStatus,
}: UserManagementTableProps) => {
  const { t } = useTranslation();

  const [user, setUser] = useState<User | null>(null);

  return (
    <>
      <Table headerNames={tableHeaders}>
        {loading ? (
          <TableSkeleton />
        ) : (
          <UserManagementTableBody
            users={users}
            userStatues={userStatues}
            userRoles={userRoles}
            loadingIds={loadingIds}
            updateUserRole={updateUserRole}
            updateUserStatus={updateUserStatus}
            onClick={onClick}
          />
        )}
      </Table>
    </>
  );
};

export default UserManagementTable;
