import React, { useEffect, useState } from "react";
import { isMobile } from "react-device-detect";

import {
  CategoryWrapper,
  DropDownWrapper,
  Results,
  Title,
  UploadFileWrapper,
  Wrapper,
} from "./ImportData.style";

import ImportExcelCard from "../../../components/desktop/importData/importExcelCard/ImportExcelCard";
import ImportDataTable from "../../../components/desktop/importData/importDataTable/ImportDataTable";
import { useAppDispatch, useAppSelector } from "../../../store";
import {
  GenerateUploadUrl,
  getFileUrl,
  getList,
} from "../../../store/report/reportAction";
import UploadModal from "../../../components/desktop/importData/uploadModal/UploadModal";
import {
  downloadFile,
  generateCategoryFilterData,
  uploadFile,
} from "../../../helpers/helper";
import { useTranslation } from "react-i18next";
import Dropdown from "../../../components/shared/dropdown/Dropdown";
import { ExcelFileType } from "../../../enums/enum";
import { ActionType } from "../../../store/type";
import { GenerateUploadUrlRequest } from "../../../store/report/report.types";
import Pagination from "../../../components/shared/pagination/Pagination";
import ImportDataMobile from "../../../components/mobile/importData/ImportDataMobile";

const tableHeaders = [
  "ImportData.UploadedBy",
  "ImportData.UploadTime",
  "ImportData.Status",
  "ImportData.File",
  "ImportData.Year",
  "ImportData.Category",
  "",
];

const ImportData = () => {
  const dispatch = useAppDispatch();
  const user = useAppSelector((state) => state.user.user);
  const selectedCompany = useAppSelector((state) => state.user.selectedCompany);
  const isLoading = useAppSelector((state) => state.report.loading);
  const [errorMessage, setErrorMessage] = useState("");

  const reportItems = useAppSelector((state) => state.report.reportList);
  const [loadingIds, setLoadingIds] = useState<Array<number>>([]);
  const [openModalType, setOpenModalType] = useState<number | null>(null);
  const [selectedValue, setSelectedValue] = useState("");
  const [uploadLoader, setUploadLoader] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const pageSize = 20;

  useEffect(() => {
    const ClientId = user?.id.toString() || "";
    const CompanyId = selectedCompany?.id.toString() || "";

    if (selectedValue) {
      dispatch(
        getList({
          ClientId,
          CompanyId,
          Page: currentPage,
          PageSize: pageSize,
          ExcelFileType: +selectedValue,
        })
      );
    } else {
      dispatch(
        getList({ ClientId, CompanyId, Page: currentPage, PageSize: pageSize })
      );
    }
  }, [dispatch, user?.id, selectedCompany, currentPage, selectedValue]);

  const handleDownload = (id: number) => {
    setLoadingIds((prev) => [...prev, id]);
    dispatch(getFileUrl({ id }))
      .then((action: ActionType) => {
        if (action.error) {
          setErrorMessage(action.error?.message || "");
          setLoadingIds((prev) =>
            prev.filter((loadingItemId) => loadingItemId !== id)
          );
        }

        if (action?.payload?.url) {
          downloadFile(action?.payload.url);
          setLoadingIds((prev) =>
            prev.filter((loadingItemId) => loadingItemId !== id)
          );
        }
      })
      .catch(() => {});
  };

  const handleUpload = (file: File, year: string | null) => {
    setErrorMessage("");
    setUploadLoader(true);

    try {
      const parts = file.name.split(".");
      let data: GenerateUploadUrlRequest = {
        companyId: selectedCompany?.id!,
        excelFileType: openModalType!,
        fileName: file.name,
        fileExtension: parts[parts.length - 1],
      };

      if (year) {
        data.year = +year;
      }

      dispatch(GenerateUploadUrl(data)).then((action: ActionType) => {
        if (action?.payload?.url) {
          uploadFile(file, action?.payload?.url)
            .then(() => {
              setOpenModalType(null);
              setUploadLoader(false);
              if (user?.id && selectedCompany) {
                const ClientId = user?.id.toString() || "";
                const CompanyId = selectedCompany?.id.toString() || "";
                const Page = 1;
                const PageSize = 100;
                dispatch(getList({ ClientId, CompanyId, Page, PageSize }));
              }
            })
            .catch(() => {
              setUploadLoader(false);
            });
        }

        if (action?.error?.message) {
          setErrorMessage(action.error.message);
          setUploadLoader(false);
        }
      });
    } catch (e) {}
  };

  const itemsPerPage = (
    totalItems: number,
    numberOfItemsPerPage: number,
    page: number
  ) => {
    return `Showing ${
      totalItems > page * numberOfItemsPerPage
        ? page * numberOfItemsPerPage
        : totalItems
    }  from ${totalItems} results`;
  };
  const { t } = useTranslation();

  return (
    <>
      <Wrapper>
        <UploadFileWrapper>
          <ImportExcelCard
            title={t("ImportData.ClientCardTitle")}
            handleOpenUpload={() => {
              setOpenModalType(ExcelFileType.Clients);
            }}
          />
          <ImportExcelCard
            title={t("ImportData.AccountCardTitle")}
            handleOpenUpload={() => {
              setOpenModalType(ExcelFileType.Account);
            }}
          />
          <ImportExcelCard
            title={t("ImportData.TransactionCardTitle")}
            handleOpenUpload={() => {
              setOpenModalType(ExcelFileType.Transaction);
            }}
          />
        </UploadFileWrapper>
        <CategoryWrapper>
          <div>
            <Title>{t("ImportData.TableTitle")}</Title>
            <Results>
              {itemsPerPage(
                reportItems.count,
                reportItems.pageSize,
                reportItems.page
              )}
            </Results>
          </div>
          <DropDownWrapper>
            <Dropdown
              title={t("CategoryFilter.Category")}
              options={generateCategoryFilterData()}
              selectedValue={selectedValue}
              handleSelect={(value) => {
                setSelectedValue(value);
              }}
            />
          </DropDownWrapper>
        </CategoryWrapper>
        {isMobile ? (
          <ImportDataMobile
            data={reportItems.items}
            tableHeaders={tableHeaders}
            handleDownload={handleDownload}
            loadingIds={loadingIds}
          />
        ) : (
          <ImportDataTable
            tableHeaders={tableHeaders}
            loading={isLoading === "pending"}
            loadingIds={loadingIds}
            handleDownload={handleDownload}
            reportItems={reportItems.items}
          />
        )}

        {Boolean(reportItems.count) && (
          <Pagination
            selectedPage={currentPage}
            limit={pageSize}
            siblings={1}
            totalPage={Math.ceil(reportItems.count / pageSize)}
            handlePage={(pageIndex) => {
              setCurrentPage(pageIndex);
            }}
          />
        )}
      </Wrapper>
      {openModalType !== null && (
        <UploadModal
          hasYear={
            openModalType === ExcelFileType.Transaction ||
            openModalType === ExcelFileType.Account
          }
          isLoading={isLoading === "pending" || uploadLoader}
          errorMessage={errorMessage}
          handleSubmit={handleUpload}
          handleClose={() => {
            setOpenModalType(null);
          }}
        />
      )}
    </>
  );
};

export default ImportData;
