import { ActionReducerMapBuilder, createSlice } from "@reduxjs/toolkit";
import { ChartGroupTypes, PeriodType } from "../../enums/enum";
import { LoaderType } from "../type";

import {
  BarChartDataRecord,
  generateBarChartData,
  generateBarChartDataWithKeyForGroups,
  generateCashFlowGroupedChartDataForGroups,
  generateCashFlowOutcome,
  generateDonutChartData,
  generateDonutChartDataWithKeyForGroups,
  generateGroupedChartData,
  generateGroupedChartDataWithKeyForGroups,
  generateProfitLoseGroupedChartDataForGroups,
  generateProfitLossNetIncome,
  generateProfitLossNetIncomePercentage,
  getAllAccountNames,
  getAllNamesWithKey,
} from "../../helpers/dashbordHelper";
import {
  CashFlow,
  CashFlowDirectionType,
  Expenses,
  ExpensesData,
  Filter,
  FrontSideFilter,
  MarketData,
  ProfitLostData,
  ReportalReportItem,
  Revenue,
  RevenueData,
} from "./dashboard.types";

import {
  getCashFlowInfo,
  getExpensesInfo,
  getMarketAllCompanyInfo,
  getMarketCompanyInfo,
  getProfitLossInfo,
  getReportalReport,
  getRevenueInfo,
} from "./dashboardAction";
import moment from "moment";

export type DashboardState = {
  hasError: boolean;
  loading: LoaderType;
  revenueData: Revenue | null;
  revenueGroupFilterOptions: Array<{ label: string; value: string }>;
  revenue: {
    groupedChartData: {
      data: Array<any>;
      labels: Array<string>;
    };
    donutChartData: any;
    barsData: Array<{ label: string; data: Array<BarChartDataRecord> }>;
  };
  groupedRevenue: {
    groupedChartData: {
      data: Array<any>;
      labels: Array<string>;
    };
    donutChartData: any;
    barsData: Array<BarChartDataRecord>;
  };
  branchRevenue: {
    groupedChartData: {
      data: Array<any>;
      labels: Array<string>;
    };
    donutChartData: any;
    barsData: Array<BarChartDataRecord>;
  };
  businessLineRevenue: {
    groupedChartData: {
      data: Array<any>;
      labels: Array<string>;
    };
    donutChartData: any;
    barsData: Array<BarChartDataRecord>;
  };
  expensesData: Expenses | null;
  expensesGroupFilterOptions: Array<{ label: string; value: string }>;
  expenses: {
    groupedChartData: {
      data: Array<any>;
      labels: Array<string>;
    };
    donutChartData: any;
    barsData: Array<any>;
  };
  groupedExpenses: {
    groupedChartData: {
      data: Array<any>;
      labels: Array<string>;
    };
    donutChartData: any;
    barsData: Array<any>;
  };
  branchExpenses: {
    groupedChartData: {
      data: Array<any>;
      labels: Array<string>;
    };
    donutChartData: any;
    barsData: Array<BarChartDataRecord>;
  };
  businessLineExpenses: {
    groupedChartData: {
      data: Array<any>;
      labels: Array<string>;
    };
    donutChartData: any;
    barsData: Array<BarChartDataRecord>;
  };
  profitLossLastDateUpdate: string;
  profitLoss: {
    groupedChartData: {
      data: Array<any>;
      labels: Array<string>;
    };
    netIncome: {
      data: Array<any>;
      labels: Array<string>;
    };
    netIncomePercentage: {
      data: Array<any>;
      labels: Array<string>;
    };
  };
  cashFlowInfoLastDateUpdate: string;
  cashFlowInfo: {
    groupedChartData: {
      data: Array<any>;
      labels: Array<string>;
    };
    cashIn: {
      data: Array<any>;
      labels: Array<string>;
    };
  };
  reportalData: {
    financeStanding: Array<ReportalReportItem>;
    workOutcome: Array<ReportalReportItem>;
  };
  frontSideFilter: FrontSideFilter;
  filter: Filter;
  market: MarketData;
  marketWorkOutcomeCompanyInfo: Array<{
    idCode: number;
    orgNameInReport: string;
  }>;
};

const initialState: DashboardState = {
  hasError: false,
  loading: "idle",
  revenueData: null,
  revenueGroupFilterOptions: [],
  revenue: {
    groupedChartData: { data: [], labels: [] },
    donutChartData: null,
    barsData: [],
  },
  groupedRevenue: {
    groupedChartData: { data: [], labels: [] },
    donutChartData: null,
    barsData: [],
  },
  branchRevenue: {
    groupedChartData: { data: [], labels: [] },
    donutChartData: null,
    barsData: [],
  },
  businessLineRevenue: {
    groupedChartData: { data: [], labels: [] },
    donutChartData: null,
    barsData: [],
  },
  expensesData: null,
  expensesGroupFilterOptions: [],
  expenses: {
    groupedChartData: { data: [], labels: [] },
    donutChartData: null,
    barsData: [],
  },
  groupedExpenses: {
    groupedChartData: { data: [], labels: [] },
    donutChartData: null,
    barsData: [],
  },
  branchExpenses: {
    groupedChartData: { data: [], labels: [] },
    donutChartData: null,
    barsData: [],
  },
  businessLineExpenses: {
    groupedChartData: { data: [], labels: [] },
    donutChartData: null,
    barsData: [],
  },
  profitLossLastDateUpdate: "",
  profitLoss: {
    groupedChartData: { data: [], labels: [] },
    netIncome: { data: [], labels: [] },
    netIncomePercentage: { data: [], labels: [] },
  },
  cashFlowInfoLastDateUpdate: "",
  cashFlowInfo: {
    groupedChartData: { data: [], labels: [] },
    cashIn: { data: [], labels: [] },
  },
  reportalData: { financeStanding: [], workOutcome: [] },
  market: {
    identificationCode: null,
    name: null,
    companyCategoryId: null,
    companyFormId: null,
    data: undefined,
  },
  frontSideFilter: {
    group: ChartGroupTypes.Grouped,
    lines: [],
    branches: [],
    groupNames: [],
  },
  filter: {
    periodType: PeriodType.Month,
    fromDate: moment().subtract(1, "year").format(),
    toDate: moment().format(),
  },

  marketWorkOutcomeCompanyInfo: [],
};

export const dashboardSlice = createSlice({
  name: "dashboardSlice",
  initialState,
  reducers: {
    updateFrontSideFilter: (
      state,
      { payload }: { payload: FrontSideFilter }
    ) => {
      state.frontSideFilter = payload;
    },
    resetGroupeName: (state) => {
      state.frontSideFilter.groupNames = [];
    },
    resetLineAndBranch: (state) => {
      state.frontSideFilter.lines = [];
      state.frontSideFilter.branches = [];
    },
    updateFilter: (state, { payload }: { payload: Filter }) => {
      state.filter = payload;
    },
    generateRevenueChartData: (state) => {
      const lineIds = state.frontSideFilter.lines;
      const branchIds = state.frontSideFilter.branches;
      const periodType = state.filter.periodType;
      const groupNames = state.frontSideFilter.groupNames;

      let revenueData: Array<RevenueData> = state.revenueData?.data || [];

      state.revenueGroupFilterOptions = getAllNamesWithKey(
        revenueData,
        "mappingGroupName"
      ).map((item) => ({ value: item, label: item }));

      if (lineIds.length) {
        revenueData = revenueData.filter((item) =>
          lineIds.find(
            (lineId) => lineId.value === item.companyBusinessLineId?.toString()
          )
        );
      }
      if (branchIds.length) {
        revenueData = revenueData.filter((item) =>
          branchIds.find(
            (nameItem) => nameItem.value === item.companyBranchId?.toString()
          )
        );
      }

      if (groupNames.length) {
        revenueData = revenueData.filter((item) =>
          groupNames.find(
            (nameItem) => nameItem.value === item.mappingGroupName
          )
        );
      }

      const groupKeys = getAllNamesWithKey(revenueData, "mappingGroupName");
      const branchKeys = getAllNamesWithKey(revenueData, "companyBranchName");
      const businessLineKeys = getAllNamesWithKey(
        revenueData,
        "companyBusinessLineName"
      );

      if (branchKeys.length) {
        const groupedBarDataForGroup = generateGroupedChartDataWithKeyForGroups(
          revenueData,
          branchKeys,
          periodType,
          "companyBranchName"
        );

        const donutDataForGroup = generateDonutChartDataWithKeyForGroups(
          revenueData,
          branchKeys,
          "companyBranchName"
        );

        const barsDataForGroup = generateBarChartDataWithKeyForGroups(
          revenueData,
          branchKeys,
          periodType,
          "companyBranchName"
        );

        state.branchRevenue.groupedChartData = {
          data: Object.values(groupedBarDataForGroup),
          labels: branchKeys,
        };

        state.branchRevenue.donutChartData = {
          data: donutDataForGroup.groupedData,
          sum: donutDataForGroup.totalSum,
        };

        state.branchRevenue.barsData = barsDataForGroup;
      } else {
        state.branchRevenue = {
          groupedChartData: { data: [], labels: [] },
          donutChartData: null,
          barsData: [],
        };
      }

      if (businessLineKeys.length) {
        const groupedBarDataForGroup = generateGroupedChartDataWithKeyForGroups(
          revenueData,
          businessLineKeys,
          periodType,
          "companyBusinessLineName"
        );

        const donutDataForGroup = generateDonutChartDataWithKeyForGroups(
          revenueData,
          businessLineKeys,
          "companyBusinessLineName"
        );

        const barsDataForGroup = generateBarChartDataWithKeyForGroups(
          revenueData,
          businessLineKeys,
          periodType,
          "companyBusinessLineName"
        );

        state.businessLineRevenue.groupedChartData = {
          data: Object.values(groupedBarDataForGroup),
          labels: businessLineKeys,
        };

        state.businessLineRevenue.donutChartData = {
          data: donutDataForGroup.groupedData,
          sum: donutDataForGroup.totalSum,
        };

        state.businessLineRevenue.barsData = barsDataForGroup;
      } else {
        state.businessLineRevenue = {
          groupedChartData: { data: [], labels: [] },
          donutChartData: null,
          barsData: [],
        };
      }

      if (groupKeys.length) {
        const groupedBarDataForGroup = generateGroupedChartDataWithKeyForGroups(
          revenueData,
          groupKeys,
          periodType,
          "mappingGroupName"
        );

        const donutDataForGroup = generateDonutChartDataWithKeyForGroups(
          revenueData,
          groupKeys,
          "mappingGroupName"
        );

        const barsDataForGroup = generateBarChartDataWithKeyForGroups(
          revenueData,
          groupKeys,
          periodType,
          "mappingGroupName"
        );

        state.groupedRevenue.groupedChartData = {
          data: Object.values(groupedBarDataForGroup),
          labels: groupKeys,
        };

        state.groupedRevenue.donutChartData = {
          data: donutDataForGroup.groupedData,
          sum: donutDataForGroup.totalSum,
        };

        state.groupedRevenue.barsData = barsDataForGroup;
      } else {
        state.revenue = {
          groupedChartData: { data: [], labels: [] },
          donutChartData: null,
          barsData: [],
        };
      }

      // detailed;
      const keys = getAllAccountNames(revenueData);

      if (keys.length) {
        const groupedBarData = generateGroupedChartData(
          revenueData,
          keys,
          periodType
        );
        const donutData = generateDonutChartData(revenueData, keys);

        const barsData = generateBarChartData(
          revenueData,
          groupKeys,
          keys,
          periodType
        );

        state.revenue.groupedChartData = {
          data: Object.values(groupedBarData),
          labels: keys,
        };
        state.revenue.donutChartData = {
          data: donutData.groupedData,
          sum: donutData.totalSum,
        };
        state.revenue.barsData = barsData;
      } else {
        state.groupedRevenue = {
          groupedChartData: { data: [], labels: [] },
          donutChartData: null,
          barsData: [],
        };
      }
    },
    generateExpensesChartData: (state) => {
      const lineIds = state.frontSideFilter.lines;
      const branchIds = state.frontSideFilter.branches;
      const groupNames = state.frontSideFilter.groupNames;
      const periodType = state.filter.periodType;
      let expensesData: Array<ExpensesData> = state.expensesData?.data || [];

      state.expensesGroupFilterOptions = getAllNamesWithKey(
        expensesData,
        "mappingGroupName"
      ).map((item) => ({ value: item, label: item }));

      if (lineIds.length) {
        expensesData = expensesData.filter((item) =>
          lineIds.find(
            (lineId) => lineId.value === item.companyBusinessLineId?.toString()
          )
        );
      }
      if (branchIds.length) {
        expensesData = expensesData.filter((item) =>
          branchIds.find(
            (nameItem) => nameItem.value === item.companyBranchId?.toString()
          )
        );
      }

      if (groupNames.length) {
        expensesData = expensesData.filter((item) =>
          groupNames.find(
            (nameItem) => nameItem.value === item.mappingGroupName
          )
        );
      }

      const groupKeys = getAllNamesWithKey(expensesData, "mappingGroupName");
      const branchKeys = getAllNamesWithKey(expensesData, "companyBranchName");
      const businessLineKeys = getAllNamesWithKey(
        expensesData,
        "companyBusinessLineName"
      );

      if (branchKeys.length) {
        const groupedBarDataForGroup = generateGroupedChartDataWithKeyForGroups(
          expensesData,
          branchKeys,
          periodType,
          "companyBranchName"
        );

        const donutDataForGroup = generateDonutChartDataWithKeyForGroups(
          expensesData,
          branchKeys,
          "companyBranchName"
        );

        const barsDataForGroup = generateBarChartDataWithKeyForGroups(
          expensesData,
          branchKeys,
          periodType,
          "companyBranchName"
        );

        state.branchExpenses.groupedChartData = {
          data: Object.values(groupedBarDataForGroup),
          labels: branchKeys,
        };

        state.branchExpenses.donutChartData = {
          data: donutDataForGroup.groupedData,
          sum: donutDataForGroup.totalSum,
        };

        state.branchExpenses.barsData = barsDataForGroup;
      } else {
        state.branchExpenses = {
          groupedChartData: { data: [], labels: [] },
          donutChartData: null,
          barsData: [],
        };
      }

      if (businessLineKeys.length) {
        const groupedBarDataForGroup = generateGroupedChartDataWithKeyForGroups(
          expensesData,
          businessLineKeys,
          periodType,
          "companyBusinessLineName"
        );

        const donutDataForGroup = generateDonutChartDataWithKeyForGroups(
          expensesData,
          businessLineKeys,
          "companyBusinessLineName"
        );

        const barsDataForGroup = generateBarChartDataWithKeyForGroups(
          expensesData,
          businessLineKeys,
          periodType,
          "companyBusinessLineName"
        );

        state.businessLineExpenses.groupedChartData = {
          data: Object.values(groupedBarDataForGroup),
          labels: businessLineKeys,
        };

        state.businessLineExpenses.donutChartData = {
          data: donutDataForGroup.groupedData,
          sum: donutDataForGroup.totalSum,
        };

        state.businessLineExpenses.barsData = barsDataForGroup;
      } else {
        state.businessLineExpenses = {
          groupedChartData: { data: [], labels: [] },
          donutChartData: null,
          barsData: [],
        };
      }

      if (groupKeys.length) {
        const groupedBarDataForGroup = generateGroupedChartDataWithKeyForGroups(
          expensesData,
          groupKeys,
          periodType,
          "mappingGroupName"
        );
        const donutDataForGroup = generateDonutChartDataWithKeyForGroups(
          expensesData,
          groupKeys,
          "mappingGroupName"
        );

        const barsDataForGroup = generateBarChartDataWithKeyForGroups(
          expensesData,
          groupKeys,
          periodType,
          "mappingGroupName"
        );

        state.groupedExpenses.groupedChartData = {
          data: Object.values(groupedBarDataForGroup),
          labels: groupKeys,
        };

        state.groupedExpenses.donutChartData = {
          data: donutDataForGroup.groupedData,
          sum: donutDataForGroup.totalSum,
        };
        state.groupedExpenses.barsData = barsDataForGroup;
      } else {
        state.expenses = {
          groupedChartData: { data: [], labels: [] },
          donutChartData: null,
          barsData: [],
        };
      }

      // detailed
      const keys = getAllAccountNames(expensesData);

      if (keys.length) {
        const groupedBarData = generateGroupedChartData(
          expensesData,
          keys,
          periodType
        );
        const donutData = generateDonutChartData(expensesData, keys);
        const barsData = generateBarChartData(
          expensesData,
          groupKeys,
          keys,
          periodType
        );
        state.expenses.donutChartData = {
          data: donutData.groupedData,
          sum: donutData.totalSum,
        };
        state.expenses.barsData = barsData;
        state.expenses.groupedChartData = {
          data: Object.values(groupedBarData),
          labels: keys,
        };
      } else {
        state.groupedExpenses = {
          groupedChartData: { data: [], labels: [] },
          donutChartData: null,
          barsData: [],
        };
      }
    },
  },
  extraReducers: (builder: ActionReducerMapBuilder<DashboardState>) => {
    builder
      //getRevenueInfo
      .addCase(getRevenueInfo.pending, (state) => {
        state.loading = "pending";
      })
      .addCase(getRevenueInfo.fulfilled, (state, { payload }) => {
        state.revenueData = payload;
        state.loading = "succeeded";
      })
      .addCase(getRevenueInfo.rejected, (state) => {
        state.loading = "failed";
        state.hasError = true;
      })
      .addCase(getExpensesInfo.pending, (state) => {
        state.loading = "pending";
      })
      .addCase(getExpensesInfo.fulfilled, (state, { payload }) => {
        // grouped
        state.expensesData = payload;

        state.loading = "succeeded";
      })

      .addCase(getExpensesInfo.rejected, (state) => {
        state.loading = "failed";
        state.hasError = true;
      })

      // getProfitLossInfo
      .addCase(getProfitLossInfo.pending, (state) => {
        state.loading = "pending";
      })
      .addCase(getProfitLossInfo.fulfilled, (state, { payload }) => {
        // grouped
        state.profitLossLastDateUpdate = payload.lastUpdateTime;
        let groupKeys: Array<string> = [];
        let hasCashIn = false;
        let hasCashOut = false;

        payload.data.forEach((element) => {
          hasCashIn ||= element.totalProfit !== 0;
          hasCashOut ||= element.totalExpense !== 0;
        });

        if (hasCashIn || hasCashOut) {
          groupKeys = ["Total Income", "Total Expense"];
        }

        if (groupKeys.length) {
          const data =
            payload.data.map((item) => ({
              [groupKeys[0]]: item.totalProfit,
              [groupKeys[1]]: item.totalExpense,
              period: item.period,
            })) || [];

          const groupedBarDataForGroup =
            generateProfitLoseGroupedChartDataForGroups(
              data as Array<ProfitLostData>,
              payload.periodType
            );

          const netIncome = generateProfitLossNetIncome(
            ["Income"],
            data as Array<ProfitLostData>,
            payload.periodType
          );

          const incomePercentage = generateProfitLossNetIncomePercentage(
            ["Income"],
            data as Array<ProfitLostData>,
            payload.periodType
          );

          state.profitLoss.netIncome = netIncome;
          state.profitLoss.netIncomePercentage = incomePercentage;

          state.profitLoss.groupedChartData = {
            data: Object.values(groupedBarDataForGroup),
            labels: groupKeys,
          };
        } else {
          state.profitLoss = {
            groupedChartData: { data: [], labels: [] },
            netIncome: { data: [], labels: [] },
            netIncomePercentage: { data: [], labels: [] },
          };
        }

        state.loading = "succeeded";
      })
      .addCase(getProfitLossInfo.rejected, (state) => {
        state.loading = "failed";
        state.hasError = true;
      })

      // cashFlowInfo
      .addCase(getCashFlowInfo.pending, (state) => {
        state.loading = "pending";
      })
      .addCase(getCashFlowInfo.fulfilled, (state, { payload }) => {
        state.cashFlowInfoLastDateUpdate = payload.lastUpdateTime;

        let groupKeys: Array<string> = [];
        let hasCashIn = false;
        let hasCashOut = false;

        payload.data.forEach((element) => {
          hasCashIn =
            element.cashFlowDirection === CashFlowDirectionType.Received;
          hasCashOut = element.cashFlowDirection === CashFlowDirectionType.Paid;
        });

        if (hasCashIn || hasCashOut) {
          groupKeys = ["Cash In", "Cash Out"];
        }

        if (groupKeys.length) {
          const groupedBarDataForGroup =
            generateCashFlowGroupedChartDataForGroups(
              payload.data as Array<CashFlow>,
              payload.periodType
            );

          const cashIn = generateCashFlowOutcome(
            ["Net Cash Flow"],
            payload.data as Array<CashFlow>,
            payload.periodType
          );
          state.cashFlowInfo.cashIn = cashIn;
          state.cashFlowInfo.groupedChartData = {
            data: groupedBarDataForGroup,
            labels: groupKeys,
          };
        } else {
          state.cashFlowInfo = {
            groupedChartData: { data: [], labels: [] },
            cashIn: { data: [], labels: [] },
          };
        }

        state.loading = "succeeded";
      })
      .addCase(getCashFlowInfo.rejected, (state) => {
        state.loading = "failed";
        state.hasError = true;
      });
    // getReportalReport
    builder
      .addCase(getReportalReport.pending, (state) => {
        state.loading = "pending";
      })
      .addCase(getReportalReport.fulfilled, (state, { payload }) => {
        state.loading = "succeeded";
        state.reportalData = payload;
      })

      .addCase(getReportalReport.rejected, (state) => {
        state.loading = "failed";
        state.hasError = true;
      })

      // get reportal finance data

      // get Market info
      .addCase(getMarketCompanyInfo.pending, (state) => {
        state.loading = "pending";
      })
      .addCase(getMarketCompanyInfo.fulfilled, (state, { payload }) => {
        state.loading = "succeeded";
        state.market = payload;
      })
      .addCase(getMarketCompanyInfo.rejected, (state) => {
        state.loading = "failed";
      })
      // get Market company info
      .addCase(getMarketAllCompanyInfo.pending, (state) => {
        state.loading = "pending";
      })
      .addCase(getMarketAllCompanyInfo.fulfilled, (state, { payload }) => {
        state.marketWorkOutcomeCompanyInfo = payload;
        state.loading = "succeeded";
      })
      .addCase(getMarketAllCompanyInfo.rejected, (state) => {
        state.loading = "failed";
      });
  },
});

export const {
  updateFrontSideFilter,
  updateFilter,
  generateRevenueChartData,
  generateExpensesChartData,
  resetLineAndBranch,
  resetGroupeName,
} = dashboardSlice.actions;

export default dashboardSlice.reducer;
