import React, { ReactNode } from "react";
import { Footer, Header, Overlay, Title, Wrapper } from "./Modal.style";

import { isMobile } from "react-device-detect";
import CompactButton from "../compactButton/CompactButton";

interface ModalProps {
  size: "medium" | "small";
  modalTitle: string;
  children: ReactNode;
  footer: ReactNode;
  onClose: () => void;
}

//  todo need change ButtonIcon to compact button
const Modal = ({ size, modalTitle, children, footer, onClose }: ModalProps) => {
  return (
    <>
      <Overlay onClick={onClose} />
      <Wrapper isMobile={isMobile} size={size}>
        <Header size={size}>
          <Title>{modalTitle}</Title>
          <CompactButton variant="ghost" size="large" onClick={onClose} />
        </Header>
        {children}
        <Footer>{footer}</Footer>
      </Wrapper>
    </>
  );
};

export default Modal;
