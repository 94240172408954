import { Formik, Form, Field, FormikHelpers } from "formik";

import {
  Action,
  ContentTitle,
  ContentWrapper,
  FooterButtonWrapper,
  Header,
  InputWrapper,
  SubTitle,
  Title,
  Wrapper,
} from "./EditMappingItemModal.style";
import { useTranslation } from "react-i18next";
import Select from "../../../v2/select/Select";
import Modal from "../../../v2/modal/Modal";
import Button from "../../../v2/button/Button";
import Input from "../../../v2/input/Input";
import { useRef } from "react";

interface FormValues {
  groupId: string;
  name: string;
  accountId: string;
  creditRePortalMappingId: string | null;
  debitRePortalMappingId: string | null;
  companyBranchId: string | null;
  companyBusinessLineId: string | null;
  accountMappingCashFlowCashInId: null | string;
  accountMappingCashFlowCashOutId: string | null;
}

interface EditMappingItemModalProps {
  loading: boolean;
  initialValues: FormValues;
  portalMappingOptions: Array<{ value: string; label: string }>;
  companyBranchesOptions: Array<{ value: string; label: string }>;
  companyBusinessLineOptions: Array<{ value: string; label: string }>;
  cashFlowReceiveOptions: Array<{ value: string; label: string }>;
  cashFlowPaidOptions: Array<{ value: string; label: string }>;
  groupOptions: Array<{ value: string; label: string }>;
  handleSubmit: (data: {
    groupId: number;
    name: string;
    accountId: string;
    creditRePortalMappingId: number | null;
    debitRePortalMappingId: number | null;
    companyBranchId: number | null;
    companyBusinessLineId: number | null;
    accountMappingCashFlowCashInId: number | null;
    accountMappingCashFlowCashOutId: number | null;
  }) => void;
  handleClose: () => void;
}
const EditMappingItemModal = ({
  loading,
  initialValues,
  portalMappingOptions,
  companyBranchesOptions,
  companyBusinessLineOptions,
  cashFlowReceiveOptions,
  cashFlowPaidOptions,
  groupOptions,
  handleClose,
  handleSubmit,
}: EditMappingItemModalProps) => {
  const { t } = useTranslation();
  const formikRef = useRef<FormikHelpers<FormValues> | null>(null);

  return (
    <Modal
      size="medium"
      modalTitle={t("EditMapping.ModalTitle")}
      onClose={handleClose}
      footer={
        <FooterButtonWrapper>
          <Button
            label={t("EditMapping.CloseButton")}
            size="large"
            variant="text"
            onClick={handleClose}
          />
          <Button
            loading={loading}
            label={t("EditMapping.EditButton")}
            size="large"
            variant="filled"
            onClick={() => {
              formikRef.current?.submitForm();
            }}
          />
        </FooterButtonWrapper>
      }
    >
      <Formik
        innerRef={formikRef as any}
        initialValues={initialValues}
        onSubmit={({
          groupId,
          name,
          accountId,
          creditRePortalMappingId,
          debitRePortalMappingId,
          companyBranchId,
          companyBusinessLineId,
          accountMappingCashFlowCashInId,
          accountMappingCashFlowCashOutId,
        }) => {
          handleSubmit({
            groupId: Number(groupId),
            name,
            accountId,
            creditRePortalMappingId: creditRePortalMappingId
              ? +creditRePortalMappingId
              : null,
            debitRePortalMappingId: debitRePortalMappingId
              ? +debitRePortalMappingId
              : null,
            companyBranchId: companyBranchId ? +companyBranchId : null,
            companyBusinessLineId: companyBusinessLineId
              ? +companyBusinessLineId
              : null,
            accountMappingCashFlowCashInId: accountMappingCashFlowCashInId
              ? +accountMappingCashFlowCashInId
              : null,
            accountMappingCashFlowCashOutId: accountMappingCashFlowCashOutId
              ? +accountMappingCashFlowCashOutId
              : null,
          });
        }}
      >
        {() => (
          <Form>
            <Wrapper>
              <Header>
                <Title>{t("EditMapping.ModalBodyTitle")}</Title>
                <SubTitle>{t("EditMapping.ModalBodySubTitle")}</SubTitle>
              </Header>
              <Action>
                <InputWrapper>
                  <Field
                    name="groupId"
                    label={t("EditMapping.GroupNameLabel")}
                    options={groupOptions}
                    component={Select}
                  />
                  <Field
                    name="accountId"
                    label={t("EditMapping.AccountIdLabel")}
                    component={Input}
                  />
                  <Field
                    name="name"
                    label={t("EditMapping.NameFieldLabel")}
                    component={Input}
                  />
                </InputWrapper>
                <ContentWrapper>
                  <ContentTitle>
                    {t("EditMapping.SARASLineElementTitle")}
                  </ContentTitle>
                  <InputWrapper>
                    <Field
                      name="debitRePortalMappingId"
                      label={t("EditMapping.DebitRePortalLabel")}
                      options={portalMappingOptions}
                      component={Select}
                    />
                    <Field
                      name="creditRePortalMappingId"
                      label={t("EditMapping.CreditRePortalLabel")}
                      options={portalMappingOptions}
                      component={Select}
                    />
                  </InputWrapper>
                </ContentWrapper>
                {Boolean(
                  companyBranchesOptions.length ||
                    Boolean(companyBusinessLineOptions.length)
                ) && (
                  <ContentWrapper>
                    <ContentTitle>
                      {t("EditMapping.CompanyInformationTitle")}
                    </ContentTitle>
                    <InputWrapper>
                      {Boolean(companyBranchesOptions.length) && (
                        <Field
                          name="companyBranchId"
                          label={t("EditMapping.CompanyBranchTitle")}
                          options={companyBranchesOptions}
                          component={Select}
                        />
                      )}
                      {Boolean(companyBusinessLineOptions.length) && (
                        <Field
                          name="companyBusinessLineId"
                          label={t("EditMapping.CompanyBusinessLineLabel")}
                          options={companyBusinessLineOptions}
                          component={Select}
                        />
                      )}
                    </InputWrapper>
                  </ContentWrapper>
                )}
                <ContentWrapper>
                  <ContentTitle>
                    {t("EditMapping.AmountCategoryTitle")}
                  </ContentTitle>
                  <InputWrapper>
                    <Field
                      name="accountMappingCashFlowCashInId"
                      reversed
                      label={t("EditMapping.ReceivedAmountTitle")}
                      options={cashFlowReceiveOptions}
                      component={Select}
                    />

                    <Field
                      reversed
                      name="accountMappingCashFlowCashOutId"
                      label={t("EditMapping.PaidAmountTitle")}
                      options={cashFlowPaidOptions}
                      component={Select}
                    />
                  </InputWrapper>
                </ContentWrapper>
              </Action>
            </Wrapper>
          </Form>
        )}
      </Formik>
    </Modal>
  );
};

export default EditMappingItemModal;
