import { css } from "@emotion/react";
import styled from "@emotion/styled";

interface isMobileProps {
  isMobile: boolean;
}

export const Container = styled.div<isMobileProps>(
  ({ isMobile, theme }) => css`
    display: flex;
    width: 100%;
    overflow: hidden;
    height: calc(100vh - 81px);
    background-color: ${theme.colors.grey100};
    ${isMobile &&
    css`
      padding-top: 75px;
      height: 100%;
    `}

    @media (max-width: ${theme.breakpoints.xl}) {
      height: calc(100vh - 73px);
    }
  `
);

export const Content = styled.div<isMobileProps>(
  ({ isMobile }) => css`
    overflow-y: auto;
    width: 100%;
    position: relative;
    height: 100%;
    padding: ${isMobile ? "0 24px" : "0 72px"};
  `
);
