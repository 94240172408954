import { css } from "@emotion/react";
import styled from "@emotion/styled";
import {
  LinkedButtonColorsType,
  LinkedButtonSizesType,
} from "../../../types/buttonType";
import React, { useState } from "react";

export const Wrapper = styled.button(
  ({ theme }) => css`
    ${theme.fonts.HelveticaNeue}
    background-color: inherit;
    display: flex;
    align-items: center;
    gap: 4px;
    border: none;
    cursor: pointer;
  `
);

export const Label = styled.span<{
  color: LinkedButtonColorsType;
  size?: LinkedButtonSizesType;
  isHovered: boolean;
  isActive: boolean;
}>(
  ({ color, theme, size, isHovered, isActive }) => css`
    font-size: ${size === "large" ? "14px" : "12px"};
    line-height: 20px;
    letter-spacing: -0.08px;

    color: ${(() => {
      if (color === "grey") return theme.colors.textSecondary;
      if (color === "black") return theme.colors.textPrimary;
      if (color === "primary")
        return isHovered ? theme.colors.primary700 : theme.colors.primary600;
      if (color === "error")
        return isHovered ? theme.colors.negative700 : theme.colors.negative600;
    })()};
    ${isActive &&
    css`
      color: ${(() => {
        if (color === "grey") return theme.colors.textPrimary;
        if (color === "black") return theme.colors.textPrimary;
        if (color === "primary") return theme.colors.primary600;
        if (color === "error") return theme.colors.negative600;
      })()};
    `}

    ${isHovered &&
    css`
      text-decoration: underline;
      text-decoration-color: ${(() => {
        if (color === "grey") return theme.colors.textSecondary;
        if (color === "black") return theme.colors.textPrimary;
        if (color === "primary") return theme.colors.primary700;
        if (color === "error") return theme.colors.negative700;
      })()};
    `}
    ${isActive &&
    css`
      text-decoration-color: ${(() => {
        if (color === "grey") return theme.colors.textPrimary;
        if (color === "black") return theme.colors.textPrimary;
        if (color === "primary") return theme.colors.primary600;
        if (color === "error") return theme.colors.negative600;
      })()};
    `}
  `
);
