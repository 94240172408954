import React from "react";
import { isMobile } from "react-device-detect";
import { useTranslation } from "react-i18next";
import ButtonIcon from "../../../shared/buttonIcon/ButtonIcon";
import Icon, { IconNameOptions } from "../../../shared/icon/Icon";
import Button from "../../../v2/button/Button";
import {
  ActionWRapper,
  ConnectedStatusContentWrapper,
  ValidIconWrapper,
  InValidIconWrapper,
  ValidText,
  InValidText,
  ConnectText,
  Container,
  Description,
  IconWrapper,
  MainContainer,
  Title,
  TitleWrapper,
  Wrapper,
} from "./IntegrationItem.style";
import LinkedButton from "../../../v2/linkedButton/LinkedButton";

interface IntegrationItemProps {
  logo: IconNameOptions;
  title: string;
  description: string;
  isConnected: boolean;
  isValid?: boolean;
  hasCancellation?: boolean;
  handleCreate?: () => void;
  handleEdit?: () => void;
  handleCancel?: () => void;
}
const IntegrationItem = ({
  logo,
  title,
  description,
  isConnected,
  isValid,
  hasCancellation,
  handleCreate,
  handleEdit,
  handleCancel,
}: IntegrationItemProps) => {
  const { t } = useTranslation();
  return (
    <Wrapper isMobile={isMobile}>
      <div>
        <Icon
          iconName={logo}
          height={isMobile ? 32 : 64}
          width={isMobile ? 32 : 64}
        />
      </div>
      <Container isMobile={isMobile}>
        <MainContainer>
          <TitleWrapper>
            <Title isMobile={isMobile}>{title}</Title>
            <>
              {isConnected ? (
                isValid ? (
                  <ConnectedStatusContentWrapper>
                    <ValidIconWrapper>
                      <Icon
                        iconName="checkMark"
                        width={10}
                        height={10}
                        fill="white"
                      />
                    </ValidIconWrapper>
                    <ValidText>{t("Integration.ConnectedTitle")}</ValidText>
                  </ConnectedStatusContentWrapper>
                ) : (
                  <ConnectedStatusContentWrapper>
                    <InValidIconWrapper>
                      <Icon
                        iconName="information"
                        width={10}
                        height={10}
                        fill="white"
                      />
                    </InValidIconWrapper>
                    <InValidText>{t("Integration.Warning")}</InValidText>
                  </ConnectedStatusContentWrapper>
                )
              ) : null}
            </>
          </TitleWrapper>
          <Description isMobile={isMobile}>{description}</Description>
        </MainContainer>
        {isConnected ? (
          <ActionWRapper isConnected={isConnected}>
            <Button
              color="error"
              label={t("Integration.CancellationButton")}
              size="small"
              variant="lighter"
              disabled={!hasCancellation}
              onClick={() => {
                if (handleCancel) {
                  handleCancel();
                }
              }}
            />

            <ButtonIcon
              variant="lighter"
              iconName="edit_v2"
              size="small"
              onClick={() => {
                if (handleEdit) {
                  handleEdit();
                }
              }}
            />
          </ActionWRapper>
        ) : (
          <LinkedButton
            label={t("Integration.ConnectButton")}
            color="primary"
            onClick={() => {
              if (handleCreate) {
                handleCreate();
              }
            }}
          />
        )}
      </Container>
    </Wrapper>
  );
};

export default IntegrationItem;
