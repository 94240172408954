import React from "react";
import { RevenueServices } from "../../../store/report/report.types";

import { TableRow, TableWrapper, Wrapper } from "./RevenueServiceMobile.style";

interface RevenueServicesMobileProps {
  data: RevenueServices;
  dataHeaders: Array<string>;
}

const RevenueServicesMobile = ({
  data,
  dataHeaders,
}: RevenueServicesMobileProps) => {
  return (
    <TableWrapper>
      {data.items.map((item, index) => (
        <Wrapper key={index}>
          <TableRow>
            <div>{dataHeaders[0]}</div>
            {item.tin}
          </TableRow>
          <TableRow>
            <div>{dataHeaders[1]}</div>
            {item.fullName}
          </TableRow>
          <TableRow>
            <div>{dataHeaders[2]}</div>
            {item.birthDate}
          </TableRow>
          <TableRow>
            <div>{dataHeaders[3]}</div>
            {item.citizenship}
          </TableRow>
          <TableRow>
            <div>{dataHeaders[4]}</div>
            {item.genderTxt}
          </TableRow>
          <TableRow>
            <div>{dataHeaders[5]}</div>
            {item.phone}
          </TableRow>
          <TableRow>
            <div>{dataHeaders[6]}</div>
            {item.workTypeText}
          </TableRow>
        </Wrapper>
      ))}
    </TableWrapper>
  );
};
export default RevenueServicesMobile;
