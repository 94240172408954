import styled from "@emotion/styled";
import { css } from "@emotion/react";
import {
  CompactSizesType,
  CompactVariantType,
} from "../../../types/buttonType";

export const Wrapper = styled.div<{
  size: CompactSizesType;
  variant: CompactVariantType;
}>(
  ({ variant, theme, size }) => css`
    cursor: pointer;
    border-radius: 6px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: ${theme.colors.white};
    ${size === "large" &&
    css`
      width: 20px;
      height: 20px;
    `}
    ${size === "small" &&
    css`
      width: 18px;
      height: 18px;
    `}

    ${variant === "stroke" &&
    css`
      border: 1px solid ${theme.colors.greyShade200};
    `}
    ${variant === "light" &&
    css`
      box-shadow: 0px 2px 4px 0px rgba(27, 28, 29, 0.04);
    `}
    &:hover {
      background-color: ${theme.colors.grey100};
    }
    &:active {
      background: ${theme.colors.textPrimary};
      box-shadow: 0px 1px 2px 0px rgba(82, 88, 102, 0.06);
    }
  `
);
