import {
  ButtonWrapper,
  ContentWrapper,
  CreatedContentWrapper,
  IconWrapper,
  SubTitle,
  SuccessPageContent,
  Title,
  Wrapper,
} from "./AddGroupModal.style";

import { useTranslation } from "react-i18next";
import Button from "../../../v2/button/Button";
import Input from "../../../v2/input/Input";
import Modal from "../../../v2/modal/Modal";
import Icon from "../../../shared/icon/Icon";

interface AddGroupModalProps {
  value: string;
  isEdit: boolean;
  loading: boolean;
  isCreated: boolean;
  setValue: (text: string) => void;
  handleSubmit: () => void;
  handleClose: () => void;
}
const AddGroupModal = ({
  value,
  loading,
  isCreated,
  isEdit,
  setValue,
  handleSubmit,
  handleClose,
}: AddGroupModalProps) => {
  const { t } = useTranslation();
  return (
    <Modal
      footer={
        <ButtonWrapper>
          <Button
            label={t("AddGroupModal.CloseButton")}
            size="medium"
            variant="text"
            onClick={handleClose}
          />
          {!isCreated && (
            <Button
              loading={loading}
              label={
                isEdit
                  ? t("AddGroupModal.EditButton")
                  : t("AddGroupModal.AddButton")
              }
              size="medium"
              variant="filled"
              onClick={() => {
                handleSubmit();
              }}
            />
          )}
        </ButtonWrapper>
      }
      size="small"
      modalTitle={
        isEdit
          ? t("AddGroupModal.ModalTitleEdit")
          : t("AddGroupModal.ModalTitleAdd")
      }
      onClose={handleClose}
    >
      {!isCreated ? (
        <Wrapper>
          <ContentWrapper>
            <Title>{t("AddGroupModal.ModalBodyTitle")}</Title>
            <SubTitle>{t("AddGroupModal.ModalBodySubTitle")}</SubTitle>
          </ContentWrapper>
          <Input
            id={"groupName"}
            label={t("AddGroupModal.InputPlaceHolder")}
            value={value}
            setValue={setValue}
          />
        </Wrapper>
      ) : (
        <CreatedContentWrapper>
          <IconWrapper>
            <Icon fill="white" iconName="checkMark" height={30} width={30} />
          </IconWrapper>
          <SuccessPageContent>
            <Title>{t("Mapping.MappedElements")}</Title>
            <SubTitle>{t("Mapping.CreteGroupSuccessText")}</SubTitle>
          </SuccessPageContent>
        </CreatedContentWrapper>
      )}
    </Modal>
  );
};

export default AddGroupModal;
