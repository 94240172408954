import { css } from "@emotion/react";
import styled from "@emotion/styled";

export const Wrapper = styled.div<{ isMobile: boolean }>(
  ({ isMobile }) => css`
    padding: ${isMobile ? "32px 24px" : " 32px 64px"};
    display: flex;
    flex-direction: column;
    gap: 16px;
    align-items: center;
  `
);

export const IconWrapper = styled.div(
  ({ theme }) => css`
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 64px;
    height: 64px;
    background-color: ${theme.colors.success600};
  `
);
export const ContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 8px;
  text-align: center;
`;

export const Title = styled.div(
  ({ theme }) => css`
    color: ${theme.colors.black005};
    font-size: 16px;
    line-height: normal;
  `
);
export const SubTitle = styled.div(
  ({ theme }) => css`
    color: ${theme.colors.grey700};
    ${theme.fonts.HelveticaLight}
    font-size: 12px;
    line-height: normal;
  `
);
