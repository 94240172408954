import React, { useEffect, useRef, useState } from "react";
import { isMobile } from "react-device-detect";

import Icon from "../../shared/icon/Icon";
import {
  Wrapper,
  ContentWrapper,
  ArrowWrapper,
  Content,
  DropDownContent,
  DropDownItem,
  DropDownTitle,
} from "./UserInfoDropdown.style";

import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useAppDispatch, useAppSelector } from "../../../store";
import { logout } from "../../../store/user/userSlice";
import { PageURL } from "../../../constants/router";
import { checkPermissionForRoute } from "../../../helpers/helper";

const UserInfoDropdown = () => {
  const [open, setOpen] = useState(false);
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { t } = useTranslation();
  const headerDropDownRef = useRef<HTMLDivElement>(null);

  const handleLogout = () => {
    dispatch(logout());
  };
  const user = useAppSelector((state) => state.user.user);

  const checkPermission = (path: string) => {
    if (user) {
      return checkPermissionForRoute(path, PageURL, user.permissions);
    }
    return false;
  };

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (
        headerDropDownRef?.current &&
        !headerDropDownRef.current.contains(event.target as Node)
      ) {
        setOpen(false);
      }
    };

    if (headerDropDownRef?.current) {
      document.addEventListener("mousedown", handleClickOutside);
    }

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [headerDropDownRef]);
  return (
    <Wrapper
      isMobile={isMobile}
      ref={headerDropDownRef}
      opened={open}
      onClick={() => setOpen((prev) => !prev)}
    >
      <Icon iconName="user" />
      <ContentWrapper>
        <Content>
          {user?.firstName} {user?.lastName}
        </Content>
        <ArrowWrapper className={open ? "rotate" : ""}>
          <Icon iconName="arrowDown" />
        </ArrowWrapper>
      </ContentWrapper>

      {open && (
        <DropDownContent>
          <DropDownItem
            isMobile={isMobile}
            onClick={() => navigate(PageURL.PersonalInfo.path)}
          >
            <Icon iconName="user" height={20} width={20} />
            <DropDownTitle>
              {t("PersonalData.PersonalInformation")}
            </DropDownTitle>
          </DropDownItem>
          {checkPermission(PageURL.CreateCompany.path) && (
            <DropDownItem
              isMobile={isMobile}
              onClick={() => navigate(PageURL.CreateCompany.path)}
            >
              <Icon iconName="plus_v2" height={20} width={20} />
              <DropDownTitle>{t("PersonalData.CreateCompany")}</DropDownTitle>
            </DropDownItem>
          )}
          <DropDownItem
            isMobile={isMobile}
            onClick={() => navigate(PageURL.CompanyInfo.path)}
          >
            <Icon iconName="company" height={20} width={20} />
            <DropDownTitle>{t("PersonalData.CompanyInfo")}</DropDownTitle>
          </DropDownItem>
          <DropDownItem
            isMobile={isMobile}
            onClick={() => navigate(PageURL.UserManagement.path)}
          >
            <Icon iconName="people" height={20} width={20} />
            <DropDownTitle>{t("PersonalData.UserManagement")}</DropDownTitle>
          </DropDownItem>
          <DropDownItem
            isMobile={isMobile}
            onClick={() => navigate(PageURL.UpdatePassword.path)}
          >
            <Icon iconName="password" height={20} width={20} />
            <DropDownTitle>{t("PersonalData.UpdatePassword")}</DropDownTitle>
          </DropDownItem>
          <DropDownItem
            isMobile={isMobile}
            onClick={() => navigate(PageURL.Integration.path)}
          >
            <Icon iconName="integration" height={20} width={20} />
            <DropDownTitle>{t("PersonalData.Integration")}</DropDownTitle>
          </DropDownItem>

          <DropDownItem isMobile={isMobile} logOut onClick={handleLogout}>
            <Icon iconName="logOut" height={20} width={20} />
            <DropDownTitle logOut>{t("PersonalData.LogOut")}</DropDownTitle>
          </DropDownItem>
        </DropDownContent>
      )}
    </Wrapper>
  );
};

export default UserInfoDropdown;
