import React, { useState } from "react";
import { Wrapper } from "./CompactButton.style";
import {
  CompactSizesType,
  CompactVariantType,
} from "../../../types/buttonType";
import Icon from "../../shared/icon/Icon";
interface CompactButtonProps {
  variant: CompactVariantType;
  size?: CompactSizesType;
  onClick: () => void;
}
const CompactButton = ({
  variant,
  size = "large",
  onClick,
}: CompactButtonProps) => {
  const [active, setActive] = useState(false);
  return (
    <Wrapper
      size={size}
      variant={variant}
      onClick={onClick}
      onMouseDown={() => setActive(true)}
      onMouseUp={() => setActive(false)}
      onMouseLeave={() => setActive(false)}
    >
      <Icon
        fill={active ? "white" : "default"}
        iconName="close"
        width={size === "large" ? 10 : 7}
        height={size === "large" ? 10 : 7}
      />
    </Wrapper>
  );
};

export default CompactButton;
