import { css } from "@emotion/react";
import styled from "@emotion/styled";

export const Wrapper = styled.div<{ isMobile: boolean }>(
  ({ isMobile, theme }) => css`
    padding: ${isMobile ? "24px" : "32px 64px"};
    display: flex;
    flex-direction: column;
    gap: ${isMobile ? "16px" : "24px"};
    ${theme.fonts.HelveticaNeue}
  `
);

export const TitleWrapper = styled.div`
  display: flex;
  flex-direction: column;
  text-align: center;
  gap: 6px;
`;
export const Title = styled.div(
  ({ theme }) => css`
    color: ${theme.colors.textPrimary};
    font-size: 14px;
    line-height: 18px;
  `
);
export const SubTitle = styled.div(
  ({ theme }) => css`
    ${theme.fonts.HelveticaLight}
    color: ${theme.colors.textSecondary};
    font-size: 12px;
    line-height: 18px;
  `
);
export const ButtonWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
`;
export const ListWrapper = styled.div(
  ({ theme }) => css`
    padding: 4px 0;
    border-radius: 12px;
    border: 1px solid ${theme.colors.greyShade50};
  `
);

export const Item = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
  cursor: pointer;
`;
export const CheckboxWrapper = styled.div`
  padding-left: 16px;
`;
export const Content = styled.p<{ lastItem: boolean }>(
  ({ theme, lastItem }) => css`
    ${theme.fonts.HelveticaLight}
    color: ${theme.colors.textPrimary};
    padding: 12px 24px 12px 0;
    line-height: normal;
    font-size: 12px;
    width: 100%;
    ${!lastItem &&
    css`
      border-bottom: 1px solid ${theme.colors.greyShade50};
    `}
  `
);
