import React from "react";
import { Wrapper } from "./Checkbox.style";

interface CustomCheckboxProps {
  checked: boolean;
  onClick: (checked: boolean) => void;
}

const CustomCheckbox: React.FC<CustomCheckboxProps> = ({
  checked,
  onClick,
}) => {
  const handleClick = (e: React.MouseEvent<HTMLDivElement>) => {
    onClick(!checked);
  };

  return (
    <Wrapper isChecked={checked} onClick={handleClick}>
      {checked && (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 24 24"
          fill="white"
          width="16px"
          height="16px"
        >
          <path d="M9 16.2L4.8 12l-1.4 1.4L9 19 21 7 19.6 5.6 9 16.2z" />
        </svg>
      )}
    </Wrapper>
  );
};

export default CustomCheckbox;
