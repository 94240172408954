import React, { useState } from "react";
import Icon from "../../../shared/icon/Icon";
import { HoverItem, Wrapper } from "./InformationNumber.style";

interface InformationNumberProps {
  quantity?: number;
  warningQuantity?: number;
}
const InformationNumber = ({
  quantity,
  warningQuantity,
}: InformationNumberProps) => {
  const [isHovered, setIsHovered] = useState(false);
  if (
    quantity === undefined &&
    (warningQuantity === undefined || !warningQuantity)
  )
    return null;

  return (
    <Wrapper
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
      hasWarning={!!warningQuantity}
    >
      {warningQuantity && isHovered && <HoverItem>Elements in Error</HoverItem>}
      {warningQuantity && <Icon height={16} width={16} iconName="alert" />}
      {quantity !== undefined ? quantity : warningQuantity}
    </Wrapper>
  );
};

export default InformationNumber;
