import { css } from "@emotion/react";
import styled from "@emotion/styled";

export const Wrapper = styled.div<{ isMobile: boolean }>(
  ({ theme, isMobile }) => css`
    ${theme.fonts.HelveticaNeue}
    display: flex;
    flex-direction: column;
    gap: ${isMobile ? "16px" : "24px"};
    margin: ${isMobile ? "24px 0" : "32px 0"};
  `
);

export const Title = styled.h3<{ isMobile: boolean }>(
  ({ theme, isMobile }) => css`
    color: ${theme.colors.textPrimary};
    font-size: ${isMobile ? "18px" : "22px"};
    line-height: normal;
  `
);
export const IntegrationWrapper = styled.div<{ isMobile: boolean }>(
  ({ theme, isMobile }) => css`
    display: flex;
    flex-direction: column;
    padding: ${isMobile ? "24px 16px" : "24px"};
    gap: ${isMobile ? "24px" : "32px"};
    border-radius: 12px;
    background: ${theme.colors.white};
    box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.09);
  `
);

export const IntegrationContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
`;
export const IntegrationTitle = styled.h5<{ isMobile: boolean }>(
  ({ theme, isMobile }) => css`
    color: ${theme.colors.black};
    font-size: ${isMobile ? "14px" : "16px"};
    line-height: 20px;
  `
);
