import { css } from "@emotion/react";
import styled from "@emotion/styled";
import {
  ButtonSizesType,
  ButtonColorsType,
  ButtonVariantsType,
} from "../../../types/buttonType";

interface WrapperProps {
  size: ButtonSizesType;
  color: ButtonColorsType;
  variant: ButtonVariantsType;
  disabled: boolean;
}
export const Wrapper = styled.button<WrapperProps>(
  ({ size, variant, color, theme, disabled }) => css`
    border-radius: 8px;
    border: 1px solid transparent;
    display: flex;
    align-items: center;
    justify-content: center;
    color: ${color === "success"
      ? theme.colors.primary600
      : theme.colors.negative600};

    /* Button Sizes */
    ${size === "large" &&
    css`
      padding: 14px 16px;
    `}
    ${size === "medium" &&
    css`
      padding: 8px;
    `}
    ${size === "small" &&
    css`
      padding: 6px;
      border-radius: 6px;
    `}

    /* Button Variants */
    ${variant === "filled" &&
    css`
      color: ${theme.colors.white};
      background: ${color === "success"
        ? theme.colors.primary600
        : theme.colors.negative600};
      &:hover {
        background: ${color === "success"
          ? theme.colors.primary800
          : theme.colors.negative700};
      }
    `}
    ${variant === "outline" &&
    css`
      border: 1px solid
        ${color === "success"
          ? theme.colors.primary600
          : theme.colors.negative600};
      &:hover {
        background: ${color === "success"
          ? theme.colors.primary100
          : theme.colors.negative100};

        border: 1px solid
          ${color === "success"
            ? theme.colors.primary100
            : theme.colors.negative100};
      }
      &:active {
        border: 1px solid
          ${color === "success"
            ? theme.colors.primary600
            : theme.colors.negative600};
        background: ${theme.colors.white};
      }
    `}
    ${variant === "lighter" &&
    css`
      background: ${color === "success"
        ? theme.colors.primary100
        : theme.colors.negative100};
      &:hover {
        background: ${color === "success"
          ? theme.colors.primary200
          : theme.colors.negative200};
      }
    `}
    ${variant === "text" &&
    css`
      background-color: transparent;
      border: 1px solid transparent;
      &:hover {
        background: ${color === "success"
          ? theme.colors.primary100
          : theme.colors.negative100};
      }
      &:active {
        border: 1px solid
          ${color === "success"
            ? theme.colors.primary600
            : theme.colors.negative600};
        background: ${theme.colors.white};
      }
    `}

    ${disabled
      ? css`
          background: ${theme.colors.greyShade100};
          color: ${theme.colors.grey500};
          border: 1px solid ${theme.colors.greyShade100};
          &:disabled {
            background: ${theme.colors.greyShade100};
            border: 1px solid ${theme.colors.greyShade100};
          }
          cursor: not-allowed;
          &:hover {
            background: ${theme.colors.greyShade100};
            border: 1px solid ${theme.colors.greyShade100};
          }
          &:active {
            background: ${theme.colors.greyShade100};
          }
        `
      : css`
          cursor: pointer;
        `}
    
    
    &:active {
      box-shadow: ${disabled
        ? "0"
        : " 0px 0px 0px 2px #fff, 0px 0px 0px 4px rgba(81, 84, 192, 0.08)"};
    }

    /* Button loader spin */
    .loading-svg {
      animation: spin 1s linear infinite;
    }

    @keyframes spin {
      0% {
        transform: rotate(0deg);
      }
      100% {
        transform: rotate(360deg);
      }
    }
  `
);
